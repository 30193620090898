import React, { useState, useEffect } from "react";
import PdfPrinter from "pdfmake";
import { useBarcode } from "react-barcodes";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import Badge from "@mui/material/Badge";
import Components from "./index";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  patientActions,
  doctorActions,
  tokenActions,
} from "../../core/actions";
import { useDispatch, useSelector } from "react-redux";
import { sideBarNavItemsServices } from "../../data";
import { helperServices } from "../../shared/helper/index";
import MessageStrip from "../../shared/messagestrip/MessageStrips";
import { useHistory } from "react-router-dom";
import { DOC_INFO, InsuranceDesk } from "../../shared/constants";
import { format, parse } from "date-fns";
import { PATIENT_SECTION, REG_INVOICE } from "../../shared/constants";
import { addMonths,isBefore, isSameDay } from "date-fns";
import { runningToken } from "../../core/actions/tokenActions";
import { Link } from "react-router-dom";
import { transform } from "lodash";

function PatientSections(props) {
  const history = useHistory();
  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [collapseBasicInfo, setCollapseBasicInfo] = useState(false);
  const [collapseAttachment, setCollapseAttachment] = useState(false);
  const [collapseComplaints, setCollapseComplaints] = useState(false);
  const [uploadPreview, setuploadPreview] = useState(false);
  const [registerPdfname, setRegisterPdfname] = useState("");
  const [patientRegInfo, setPatientRegInfo] = useState([]);
  const [collapseDoctorList, setCollapseDoctorList] = useState(false);
  const [collapseReference, setCollapseReference] = useState(false);
  const [collapseBarcode, setCollapseBarcode] = useState(false);
  const [collapseRoom, setCollapseRoom] = useState(false);
  const [collapseInsurance, setCollapseInsurance] = useState(false);
  const [callType, setCallType] = useState(false);
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState("");
  const [deskDetails, setDeskDetails] = useState({});
  const [updateTokenDashboard, setupdateTokenDashboard] = useState(false);
  const [onBoardmodal, setOnBoardModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("OutPatient");
  const [patientInfo, setPatientInfo] = useState({
    visitId: "",
    patientId: "",
    name: "",
    age: "",
    phno: "",
    id: "",
    address: "",
    adhar: "",
    age: "",
    area: "",
    bloodgroup: "",
    city: "Arkula",
    state: "Karnataka",
    lname: "",
    mname: "",
    fname: "",
    occupation: "",
    patientcode: "",
    pincode: "",
    admsnDate: "",
    dischargeDate: "",
    type: "OutPatient",
    fileNo: "",
    email: "",
    srcRef: "",
    language: "",
    education: "",
    sociostatus: "",
    tokenNumber: "",
    deskNumber: "",
    comments: "",
    status: "",
    ehrid: "",
    erpCustId: "",
    altPhno: "",
    visitDate: "",
    insurance: "",
    registeredOn: "",
    regFeesPdfUrl: "",
    emrPhono: "",
    gender: "",
    internalStatus: "",
  });
  const [regDate, setRegDate] = useState("");
  const [patientProcedureList, setPatientProcedureList] = useState({});
  const [fileUploadcontent, setfileUploadcontent] = useState();
  const [patientVisitList, setPatientVisitList] = useState();
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patientDetailsState.patient);
  const dPatient = useSelector((state) => state.patientDetailsState.dPatient);
  const patientDetails = useSelector(
    (state) => state.patientDetailsState.patientDetail
  );
  const doctInfo = useSelector((state) => state.doctorDetailsState.docInfo);
  const [lastAsssigned, setLastAssigned] = useState({});
  const [docInfo, setdocInfo] = useState({});
  const [counsInfo, setcounsInfo] = useState({});
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [selectedDate, setSelecetdDate] = useState(new Date());
  const [allvisitLists, setAllvisitLists] = useState([]);
  const [allVisitdates, setallVisitdates] = useState([]);
  const [activeSection, showactivesection] = useState([]);
  const [showPayment, setShowPayment] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [registrationCard, setRegistrationCard] = useState(false);
  const [printTOkenModal, setPrintTOkenModal] = useState(false);
  const [separatePdf, SetSeparatePdf] = useState(false);
  const [mergePdf, SetMergePdf] = useState(false);
  const [summary, setSummary] = useState({
    patientId: "",
    visitId: "",
    groupname: "",
    shortdesc: "",
    longdesc: "",
    targetid: "",
  });
  const [arrayui, setarrayui] = useState([]);
  const [complaintText, setComplaintText] = useState([]);
  const [selectedVisit, setSelecetdDateVisit] = useState();
  const [patientCalenderInfoD, setpatientCalenderInfoD] = useState({});
  const [patientCalenderInfoC, setpatientCalenderInfoC] = useState({});
  const [currentStatus, setCurrentStatus] = useState(null);
  const todayDate = format(new Date(), "yyyy-MM-dd");

  // Get the current time and format it as "HH:mm" for the time input field
  const currentTime = format(new Date(), "HH:mm");

  // Set the default values
  const [selecteddate, setSelecteddate] = useState(todayDate);
  const [selectedTime, setSelectedTime] = useState(currentTime);
  const [isOverrided, setIsOverrided] = useState(false);
  const [patientDetail, setPatientDetail] = useState({
    patientId: "",
    visitId: "",
    closecomment: "",
    closeScheduledTime: "",
  });
  const [internalStatusOut, setInternlStatusOut] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(patientActions.setPage(PATIENT_SECTION));
    console.log("patient in patientsections: ", patient);
    var role = JSON.parse(localStorage.getItem("role"));
    setRole(role);
    console.log("useEffect :", role);
    // console.log("current state before assignment : " + currentStatus);

    let patId = props.match.params.patientId;
    let vId = props.match.params.visitId;
    getDoctorList();

    //procedure list api

    if (patId && patId != "" && vId && vId != "") {
      getPatientProfile(patId, vId);
      getProcedureList(patId, vId);
      getVisitList(patId, vId);
      setCallType("Edit");
      //setPatientInfo({patientId:patId,visitId:vId })
    } else {
      setCallType("Add");
      // setOpenPatientModal(true);
    }

    console.log("Patid", patId);
    console.log("vId:", vId);

    if (role) {
      const roles = role.split(",");
      setRoles(roles);
    }

    let all_roles = helperServices.getRoles();
    console.log("allroles", all_roles);
    Object.keys(all_roles).forEach((key) => {
      console.log("allroles key", key);
      console.log("currentRole", role);
      if (key === role) {
        console.log("all_roles[key]:", all_roles[key]);
        showactivesection([...all_roles[key]]);
        setTimeout(() => {
          console.log("activesection key:", activeSection);
        }, 500);
      }
    });
  }, []);

  const getCalenderForPatient = (gvId, pateintId, type) => {
    let data = { gvid: gvId, patientId: pateintId };
    console.log("data: ", data);
    dispatch(
      patientActions.getDoctorCalenderForPatient(data, (rescb) => {
        if (rescb) {
          if (type == "doctor") {
            console.log("resCb Patient Calender", rescb);
            setpatientCalenderInfoD(rescb.calenderForPatient);
          } else if (type == "counsellor") {
            console.log("resCb Patient Calender", rescb);
            setpatientCalenderInfoC(rescb.calenderForPatient);
          }
        }
      })
    );
  };

  const getDoctorList = () => {
    let data = {};
    dispatch(
      doctorActions.getDoctorList(data, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb);
          //code to sort the doctor name list in alpahbatical order
          const sort_rescb = Object.values(resCb);
          console.log("sort_rescb", sort_rescb);
          //resCb = sort_rescb.sort((a, b) =>
          //  a.first_name && a.first_name > b.first_name && b.first_name ? 1 : -1
          //);
          //sort_rescb.sort((a, b) =>
          // a.first_name && a.first_name <b.first_name && b.first_name ? 1 : -1

          sort_rescb.sort((a, b) => {
            if (a && a.first_name && b && b.first_name) {
              return a.first_name.localeCompare(b.first_name);
            }
          });
          console.log("after_sort", sort_rescb);

          console.log("resCb: sorted", resCb);

          // },400)
        }
      })
    );
  };

  const getPatientProfile = (patId, vId) => {
    dispatch(
      patientActions.getPatientProfile(patId, (resCb) => {
        if (resCb) {
          console.log("patientprofile", resCb);
          addPatientProfileDetails(vId, patId, resCb);
          dispatch(patientActions.setPatientDetails(resCb));
          if (resCb.visit && resCb.visit.status != "close") {
            console.log("current status : ", patient.status);
            setCurrentStatus(resCb.visit.status);

            // console.log("current state assignment : " + currentStatus);
          }
          // patientInfo.regFeesPdfUrl = resCb.visit.regFeesPdfUrl;
          // console.log("REgistration fees pdf url : ",patientInfo['regFeesPdfUrl']);
          // setPatientInfo(prevState => ({
          //   ...prevState,
          //   regFeesPdfUrl: resCb.visit.regFeesPdfUrl// Storing the remaining data as a string
          // }));
          setPatientInfo(patientInfo);
          console.log("patientDetail",patientDetails);
          setallVisitdates([...resCb.visitList.reverse()]);
          // setAllvisitLists([...resCb.visitList])
          // let a = [...resCb.visitList];
          // console.log("before:",a)
          //let lastvisit =resCb.visitList.shift();
          console.log("patientInfo after regFeesPdfUrl  : ", patientInfo);
          console.log("visitList is:", resCb.visitList);
          if (resCb.visitList.length > 1) {
           let lastVisit= sixMonthsPrior(resCb.lastRegisteredOn);
            setInternlStatusOut(lastVisit)
            let sortList = resCb.visitList.shift();
            let lastvisitId = resCb.visitList[0].id;
            var visitList = resCb.visitList;
            var statusList = [];
            visitList.forEach((item) => {
              if (item.status != "close") {
                statusList.push(item.status);
                console.log("status ::: ", item.status, statusList);
              }
            });
            // setFinalStatus([...statusList]);
            let type = "showAllVisits";
            console.log("last visit id is:", lastvisitId);
            dispatch(
              patientActions.getVisitListById(lastvisitId, type, (resCb) => {
                console.log("last visit response:", resCb);
                if (resCb) {
                  let lastassigned = {
                    doctor: "",
                    counsellor: "",
                  };
                  console.log(
                    "resCb.patsearchreslt[0].visit.invoicePdf",
                    resCb.patsearchreslt[0]
                  );
                  if (resCb.patsearchreslt[0].visit.invoicePdf) {
                    setRegisterPdfname(
                      resCb.patsearchreslt[0].visit.invoicePdf
                    );
                  }
                  if (resCb.patsearchreslt[0]) {
                    setPatientRegInfo(resCb.patsearchreslt[0]);
                  }
                  if (resCb.patsearchreslt[0].person) {
                    console.log(
                      "person object in lastvisitlist :",
                      resCb.patsearchreslt[0].person
                    );
                    lastassigned.doctor =
                      resCb.patsearchreslt[0].person.displaytitle +
                      "." +
                      resCb.patsearchreslt[0].person.first_name +
                      " " +
                      resCb.patsearchreslt[0].person.last_name
                        ? resCb.patsearchreslt[0].person.last_name
                        : "";
                  }
                  setLastAssigned({ ...lastassigned });

                  if (resCb.patsearchreslt[0].counsellor) {
                    console.log(
                      "counsellor object in lastvisitlist :",
                      resCb.patsearchreslt[0].counsellor
                    );
                    lastassigned.counsellor =
                      resCb.patsearchreslt[0].counsellor.displaytitle +
                      "." +
                      resCb.patsearchreslt[0].counsellor.first_name +
                      " " +
                      resCb.patsearchreslt[0].counsellor.last_name
                        ? resCb.patsearchreslt[0].counsellor.last_name
                        : "";
                  }
                  setLastAssigned({ ...lastassigned });
                }
              })
            );
          }
        }
      })
    );
    // if(patient.typeFlag === "patientComplaint"){
    scrollToElement();

    //}
  };

    const sixMonthsPrior=(date)=> {
    let v;
    var d = new Date(date);
    var t =  new Date();
    const diffYears = t.getFullYear() - d.getFullYear();
    const diffMonths = t.getMonth() - d.getMonth();
    const totalMonths = diffYears * 12 + diffMonths;
    if (totalMonths >=6) {
      v = true;
    }
    else {
      v=false
    }
    return v 
  }

  const scrollToElement = () => {
    if (patient.typeFlag != "all") {
      setTimeout(() => {
        // setTimeout(() => {
        var doc = document.getElementById("form");
        console.log("doc:", doc);
        if (doc && doc.childNodes) {
          for (let i = 0; i < doc.childNodes.length; i++) {
            console.log("doc.childNodes[i]:", doc.childNodes[i]);

            if (
              doc.childNodes[i].id &&
              doc.childNodes[i].id == patient.typeFlag
            ) {
              doc.childNodes[i].scrollIntoView(true);
              doc.childNodes[i].scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
                alignToTop: false,
              });

              doc.childNodes[i].style.paddingTop = "70px";
            } else {
              // doc.childNodes[i].style.opacity = "0.3";
            }
          }
        }
      }, 500);
    }
  };

  const getProcedureList = (patId, vId) => {
    let type = "showAllVisits";
    console.log("-------PROCEDURE LIST API----------");
    dispatch(
      patientActions.getPatientProcedureInfo(patId, vId, type, (resCb) => {
        if (resCb) {
          console.log("procedure list in api: ", resCb.patientresp);
          console.log("procedurelist", resCb);
          setPatientProcedureList(resCb.patientresp);
          if (resCb && resCb.patientresp && resCb.patientresp.procedureslist) {
            let itemFound = false;
            let check =false;
            let override=false;
            for (let i = 0; i < resCb.patientresp.procedureslist.length; i++) {
              const item = resCb.patientresp.procedureslist[i];
              if (item &&( item.groupname === "Registration Fees" ||item.groupname === "ONBOARD_OUT_TO_IN" ||item.groupname=="REVISIT" || item.groupname ==="CONVERTTOINPATIENT"))
               {
                console.log("patient procedure list in patientsection: ", item);
                check = true;
              }else if(item && (item.groupname === "REVISIT_ONBOARD")){
                console.log("patient procedure list in patientsection: ", item);
                  check = false;
                }
                else if(item && item.groupname === "Overrided"){
                  console.log("patient procedure list in patientsection: ", item);
                    override=true; 
                }
            }

            if(check == true){
              console.log("Item not found");
              setIsOverrided(false);
            }else  {
              console.log("Item found");
              setIsOverrided(true)
            }
    
            // if (itemFound) {
            //   console.log("Item not found");
            //   setIsOverrided(true);
            // } else {
            //   setIsOverrided(false);
            // }
          }
          addPatientDetails(vId, patId, resCb);
          listComplaints(resCb.patientresp);

          dispatch(doctorActions.setPatientProcedureList(resCb.patientresp));
          console.log(
            "patient procedure list in patientsection: ",
            resCb.patientresp
          );
          console.log("patientInfo in patientsection: ", patientInfo);
        }
      })
    );
    setTimeout(() => {
      toggleBasicInfo();
    }, 500);
  };

  const listComplaints = (resp) => {
    console.log("complaint list in api: ", resp);
    //procedureList = patientProceduresList.procedureslist
    let procedureList = resp.procedureslist;
    let patientProceduresList = resp;

    console.log("procedureList:", procedureList);
    console.log("patientProcedureList:", patientProceduresList);
    if (procedureList) {
      let list = [];
      for (let proc of procedureList) {
        let complaintList = {};
        console.log("procedures complaint 45:", proc);
        if (proc.claSS == "complaint") {
          console.log("procedures complaint 55:", proc.complaint);
          if (
            patientProceduresList.visit.id &&
            patientProceduresList.visit.id == proc.visitId &&
            proc.complaint
          ) {
            console.log(
              "procedures 1: ",
              patientProceduresList.visit.id,
              "procedures complaint 1: ",
              proc.visitId,
              "visit id in patient and procedure :",
              proc.class,
              " complaint in loop proc : ",
              proc.complaint
            );
            // props.patientInfo.
            complaintList["id"] = proc.id;
            complaintList["text"] = proc.complaint;
            complaintList["edit"] = false;
            complaintList["cdt"] = proc.cdt;
            list.push(complaintList);
          }
        }
      }
      if (list.length > 0) {
        setComplaintText([...list]);
      }
      console.log("complaintlist : ", list);
    }
  };
  const formatDate = (dateString) => {
    let date = dateString.split("T");
    let time = "";
    let timePart = dateString.split("T")[1].split(".")[0]; // Extract time part and remove milliseconds
    let timeSplit = timePart.split(":");
    time =
      format(new Date(date[0]), "MMM dd yyyy") +
      " " +
      timeSplit[0] +
      ":" +
      timeSplit[1];
    return time;
  };
  //It calls after getProcedureList method
  const addPatientDetails = (vId, patId, resCb) => {
    console.log("person data in add patient:", resCb);
    console.log("person data in add patient:", vId, patId);
    // resCb.patientresp.visit.status =  "close";
    let personData = {
      visitId: vId,
      patientId: patId,
      fname: resCb.patientresp.fname,
      age: resCb.patientresp.age,
      phno: resCb.patientresp.phno ? resCb.patientresp.phno : "",
      altPhno: resCb.patientresp.altPhno ? resCb.patientresp.altPhno : "",
      id: resCb.patientresp.id ? resCb.patientresp.id : "",
      address: resCb.patientresp.address ? resCb.patientresp.address : "",
      adhar: resCb.patientresp.adhar ? resCb.patientresp.adhar : "",
      area: resCb.patientresp.area ? resCb.patientresp.area : "",
      bloodgroup: resCb.patientresp.bloodgroup
        ? resCb.patientresp.bloodgroup
        : "",
      city: resCb.patientresp.city ? resCb.patientresp.city : "",
      lname: resCb.patientresp.lname ? resCb.patientresp.lname : "",
      mname: resCb.patientresp.mname ? resCb.patientresp.mname : "",
      occupation: resCb.patientresp.occupation
        ? resCb.patientresp.occupation
        : "",
      patientcode: resCb.patientresp.patientcode
        ? resCb.patientresp.patientcode
        : "",
      pincode: resCb.patientresp.pincode ? resCb.patientresp.pincode : "",
      dob: resCb.patientresp.dob ? resCb.patientresp.dob : "",
      gender: resCb.patientresp.gender ? resCb.patientresp.gender : "",
      state: resCb.patientresp.state ? resCb.patientresp.state : "",
      email: resCb.patientresp.email ? resCb.patientresp.email : "",
      admsnDate: resCb.patientresp.visit.admsnDate
        ? format(
            new Date(resCb.patientresp.visit.admsnDate),
            "MMM dd yyyy HH:mm"
          )
        : "",

      dischargeDate: resCb.patientresp.visit.dischargeDate
        ? formatDate(resCb.patientresp.visit.dischargeDate)
        : "",
      type: resCb.patientresp.visit
        ? resCb.patientresp.visit.type
        : "InPatient",
      insurance:
        resCb.patientresp.visit && resCb.patientresp.visit.insurance
          ? resCb.patientresp.visit.insurance
          : "NO",
      fileNo: resCb.patientresp.fileNo ? resCb.patientresp.fileNo : "",
      education: resCb.patientresp.education ? resCb.patientresp.education : "",
      srcRef: resCb.patientresp.srcRef ? resCb.patientresp.srcRef : "",
      language: resCb.patientresp.language ? resCb.patientresp.language : "",
      sociostatus: resCb.patientresp.sociostatus
        ? resCb.patientresp.sociostatus
        : "",
      tokenNumber: resCb.patientresp.tokenNumber
        ? resCb.patientresp.tokenNumber
        : "",
      deskNumber: resCb.patientresp.deskNo ? resCb.patientresp.deskNo : "",
      comments: resCb.patientresp.comments ? resCb.patientresp.comments : "",
      status: resCb.patientresp.visit.status
        ? resCb.patientresp.visit.status
        : "",
      ehrid: resCb.patientresp.ehrid ? resCb.patientresp.ehrid : "",
      erpCustId: resCb.patientresp.erpCustId ? resCb.patientresp.erpCustId : "",
      visitDate: resCb.patientresp.visit
        ? resCb.patientresp.visit.visitDate
        : "",
      internalStatus: resCb.patientresp.visit
        ? resCb.patientresp.visit.internalStatus
        : "",

      registeredOn: resCb.patientresp.registeredOn
        ? resCb.patientresp.registeredOn
        : "",
      emrPhono: resCb.patientresp.emrPhono ? resCb.patientresp.emrPhono : "",
      regFeesPdfUrl: patientInfo.regFeesPdfUrl,
    };
    //const registrationDate = new Date(resCb.registeredOn);
    setRegDate(
      resCb.patientresp.registeredOn
        ? format(new Date(resCb.patientresp.registeredOn), "MMM dd yyyy")
        : ""
    );
    console.log("pateintInfo in patientsection: ", patientInfo);
    console.log("person in patientsection: ", personData);
    const keys = Object.keys(patientInfo);
    const item = Object.keys(personData);
    var itmData = { ...personData };

    for (let itm of item) {
      for (let key of keys) {
        if (itm === key) {
          console.log("patientDetail : ", itmData[itm]);
          patientInfo[key] = itmData[itm];
        }
      }
    }
    getAssignedRoom(personData);

    console.log("pateintInfo in patientsection: ", patientInfo);
  };

  //It calls after getPatientProfile method
  const addPatientProfileDetails = (vId, patId, resCb) => {
    console.log("person data in add patient:", resCb);
    console.log("person data in add patient:", vId, patId);
    let personData = {
      visitId: vId,
      patientId: patId,
      fname: resCb.fname,
      age: resCb.age,
      phno: resCb.phno ? resCb.phno : "",
      altPhno: resCb.altPhno ? resCb.altPhno : "",
      id: resCb.id ? resCb.id : "",
      address: resCb.address ? resCb.address : "",
      adhar: resCb.adhar ? resCb.adhar : "",
      area: resCb.area ? resCb.area : "",
      bloodgroup: resCb.bloodgroup ? resCb.bloodgroup : "",
      city: resCb.city ? resCb.city : "",
      lname: resCb.lname ? resCb.lname : "",
      mname: resCb.mname ? resCb.mname : "",
      occupation: resCb.occupation ? resCb.occupation : "",
      patientcode: resCb.patientcode ? resCb.patientcode : "",
      pincode: resCb.pincode ? resCb.pincode : "",
      dob: resCb.dob ? resCb.dob : "",
      gender: resCb.gender ? resCb.gender : "",
      state: resCb.state ? resCb.state : "",
      email: resCb.email ? resCb.email : "",
      admsnDate:
        resCb.visit && resCb.visit.admsnDate
          ? format(new Date(resCb.visit.admsnDate), "MMM dd yyyy HH:mm")
          : "",

      dischargeDate: resCb.visit.dischargeDate
        ? formatDate(resCb.visit.dischargeDate)
        : "",
      type: resCb.visit ? resCb.visit.type : "InPatient",
      insurance:
        resCb.visit && resCb.visit.insurance ? resCb.visit.insurance : "NO",
      fileNo: resCb.fileNo ? resCb.fileNo : "",
      education: resCb.education ? resCb.education : "",
      srcRef: resCb.srcRef ? resCb.srcRef : "",
      language: resCb.language ? resCb.language : "",
      sociostatus: resCb.sociostatus ? resCb.sociostatus : "",
      tokenNumber: resCb.tokenNumber ? resCb.tokenNumber : "",
      deskNumber: resCb.deskNo ? resCb.deskNo : "",
      comments: resCb.comments ? resCb.comments : "",
      status: resCb.visit && resCb.visit.status ? resCb.visit.status : "",
      ehrid: resCb.ehrid ? resCb.ehrid : "",
      erpCustId: resCb.erpCustId ? resCb.erpCustId : "",
      visitDate: resCb.visit ? resCb.visit.visitDate : "",
      registeredOn: resCb.registeredOn ? resCb.registeredOn : "",
      internalStatus: resCb.visit ? resCb.visit.internalStatus : "",
      emrPhono: resCb.emrPhono ? resCb.emrPhono : "",
      regFeesPdfUrl:
        resCb.visit && resCb.visit.regFeesPdfUrl
          ? resCb.visit.regFeesPdfUrl
          : "",
    };
    setRegDate(
      resCb.registeredOn
        ? format(new Date(resCb.registeredOn), "MMM dd yyyy")
        : ""
    );
    console.log("pateintInfo in patientsection: ", patientInfo);
    console.log("person in patientsection: ", personData);
    const keys = Object.keys(patientInfo);
    const item = Object.keys(personData);
    var itmData = { ...personData };

    for (let itm of item) {
      for (let key of keys) {
        if (itm === key) {
          console.log("patientDetail : ", itmData[itm]);
          patientInfo[key] = itmData[itm];
        }
      }
    }
    getAssignedRoom(personData);
    console.log("pateintInfo in patientprofile : ", patientInfo);
  };

  const getVisitList = (patId, vId) => {
    let data = {};
    data["visitId"] = vId;
    data["patientId"] = patId;
    let type = "showAllVisits";
    dispatch(
      patientActions.getVisitListById(vId, type, (resCb) => {
        if (resCb) {
          // setShowPatientProcedure(true)
          console.log(
            "getAllVisitlists :getAllVisitlists :",
            resCb.patsearchreslt
          );
          if (resCb.patsearchreslt[0].length > 0) {
            setPatientRegInfo(resCb.patsearchreslt[0]);
            // setInternlStatusOut(resCb.patsearchreslt[0].visit.internalStatus);
          }
          if (
            resCb.patsearchreslt[0].length > 0 &&
            resCb.patsearchreslt[0].visit.invoicePdf
          ) {
            setRegisterPdfname(resCb.patsearchreslt[0].visit.invoicePdf);
          }

          console.log("visitdate is", resCb.patsearchreslt[0].visit.visitDate);
          //setSelecetdDate(resCb.patsearchreslt[0].visit.visitDate);
          // setSelecetdDate(resCb.patsearchreslt[0].visit.apptdate);
          console.log("patientVisitmap", resCb.patientVisitMap);
          setDeskDetails(resCb.patientVisitMap);
          console.log(
            "person object in visitlist :",
            resCb.patsearchreslt[0].person
          );
          let docinfo = {
            counselor: "",
            email: "",
            type: "edit",
            gvId: "",
          };
          if (resCb.patsearchreslt[0].person) {
            docinfo.counselor = resCb.patsearchreslt[0].person.personId;
            docinfo.email = resCb.patsearchreslt[0].person.email;
            docinfo.gvId = resCb.patsearchreslt[0].person.gvId;
            getCalenderForPatient(docinfo.gvId, patId.toString(), "doctor");
          }

          setdocInfo({ ...docinfo });

          let counsInfo = {
            counselor: "",
            email: "",
            type: "edit",
            gvId: "",
          };

          if (resCb.patsearchreslt[0].counsellor) {
            counsInfo.counselor = resCb.patsearchreslt[0].counsellor.personId;
            counsInfo.email = resCb.patsearchreslt[0].counsellor.email;
            counsInfo.gvId = resCb.patsearchreslt[0].counsellor.gvId;
            getCalenderForPatient(
              counsInfo.gvId,
              patId.toString(),
              "counsellor"
            );
          }
          setcounsInfo({ ...counsInfo });
          // let visitdoc = (resCb.patientVisitMap.visitAssignmentList).filter((item)=> item.type == 'Doctor');
          console.log("docInfo in patientaction:", docinfo);
          console.log("counsInfo in patientaction:", counsInfo);
          setPatientVisitList(resCb.patientVisitMap);
          dispatch(patientActions.setPatientVisitList(resCb.patsearchreslt));
        }
      })
    );
  };

  const getFullName = (fname, mname, lname) => {
    var fullName = "";
    console.log("fname : ", fname);
    console.log("mname : ", mname);
    console.log("lname : ", lname);
    if (!fname || fname == null) {
      fname = "";
    }
    if (!mname || mname == null) {
      mname = "";
    }
    if (!lname || lname == null) {
      lname = "";
    }
    var pfname = fname;
    console.log("pfname : ", pfname);
    //   .toLowerCase();
    var pmname = mname;
    console.log("pmname : ", pmname);
    //   .toLowerCase();
    var plname = lname;
    console.log("plname : ", plname);
    //   .toLowerCase();
    if (pfname == null) {
      pfname = "";
    }
    if (pmname == null) {
      pmname = "";
    }
    if (plname == null) {
      plname = "";
    }
    pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1);
    pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1);
    plname = plname.charAt(0).toUpperCase() + plname.slice(1);
    if (fname && lname) {
      fullName = pfname + " " + plname;
      console.log("fullName : ", fullName);
    } else if (fname && mname) {
      fullName = pfname + " " + pmname;
      console.log("fullName : ", fullName);
    } else if (fname && mname && lname) {
      fullName = pfname + " " + pmname + " " + plname;
      console.log("fullName : ", fullName);
    } else {
      fullName = pfname + "      ";
      console.log("fullName : ", fullName);
    }
    return fullName;
  };
  const toggleBasicInfo = () => {
    setCollapseBasicInfo(!collapseBasicInfo);
  };

  const toggleAttachments = () => {
    setCollapseAttachment(!collapseAttachment);
  };
  const toggleComplaints = () => {
    setCollapseComplaints(!collapseComplaints);
  };
  const toggleDoctorList = () => {
    setCollapseDoctorList(!collapseDoctorList);
  };
  const toggleReference = () => {
    setCollapseReference(!collapseReference);
  };
  const toggleBarcode = () => {
    setCollapseBarcode(!collapseBarcode);
  };
  const toggleRoom = () => {
    setCollapseRoom(!collapseRoom);
  };
  const toggleInsurance = () => {
    setCollapseInsurance(!collapseInsurance);
  };
  const diagnosisClick = () => {
    if (dPatient) {
      this.props.history.push({
        pathname: `/DiagnosisMaster/${dPatient.basicinfo.patientId}`,
        state: {
          patientId: dPatient.basicinfo.patientId,
        },
      });
    }
  };

  const handleDocChange = (event) => {
    console.log("event:", event);
    var ServerResVal = ServerRes;
    var assigndoc = {};
    assigndoc["id"] = patientInfo.id;
    assigndoc["visit"] = {};
    assigndoc.visit["id"] = patientInfo.visitId;
    assigndoc.visit["patientId"] = patientInfo.patientId;

    console.log(" assign doc : ", patientInfo);
    if (event != null) {
      let person = {
        personId: event.gvId,
        first_name: event.firstName,
        last_name: event.lastName,
        department: event.department,
        departmentId: event.departmentId,
        role: event.role,
        type: event.type,
      };
      assigndoc["person"] = person;
      // visitdetail.counselor=event;
      console.log("event !=null : ", event);
    }
    console.log("D-appointmentdetails", assigndoc);
    dispatch(
      doctorActions.assignDoctor(assigndoc, (resCb) => {
        if (resCb) {
          console.log("Doctor Assigned Successfully:", resCb);
          if (resCb.state === "success") {
           ServerResVal .success = "Doctor Assigned Successfully";
            getDoctorList();
            setServerRes({ ...ServerResVal });
            let info = {
              counselor: resCb.va.targetid,
              email: "",
              type: "edit",
              gvId: resCb.va.targetid,
            };

            setdocInfo({ ...info });
            console.log("visit id is : ", patientInfo.visitId);
            console.log("gv id is : ", event.gvId);
            console.log("token number is: ", patientInfo.tokenNumber);
            if (
              patientInfo &&
              patientInfo.type &&
              patientInfo.type == "OutPatient"
            ) {
              console.log(
                "patientInfo.tokenNumber",
                patientInfo.tokenNumber,
                typeof patientInfo.tokenNumber
              );
              if (patientInfo && parseInt(patientInfo.tokenNumber) > 0) {
                dispatch(
                  tokenActions.recorrectToken(
                    patientInfo.visitId,
                    event.gvId,
                    (resCb) => {
                      if (resCb) {
                        console.log("token responses", resCb.token);
                      }
                    }
                  )
                );
              } else {
                dispatch(
                  tokenActions.tokenGenerate(
                    patientInfo.visitId,
                    event.gvId,
                    (resCb) => {
                      if (resCb) {
                        console.log("token responses", resCb.token);
                      }
                    }
                  )
                );
              }
            }
          } else if (resCb.state === "error") {
            ServerResVal.error = "Failed to assign doctor";
            setServerRes({ ...ServerResVal });
          }

          setTimeout(() => {
            closeAlert();
          }, 2000);
          setApiCall(true);
          // getPatientProfile(patientInfo.id, patientInfo.visitId);
          // getProcedureList(patientInfo.id, patientInfo.visitId);
          // getVisitList(patientInfo.id, patientInfo.visitId);
          setupdateTokenDashboard(true);
          console.log("ServerResVal", ServerResVal);
        }
      })
    );
  };

  const handleCounslChange = (event) => {
    console.log("handleCounslChange  : ", event);
    console.log("patientInfo  : ", patientInfo);

    // return;
    let details = { ...summary };

    details.shortdesc =
      "Added Counsellor " +
      event.displaytitle +
      " " +
      event.firstName +
      " " +
      event.lastName;
    details.groupname = "Counsellor";
    details.longdesc = "Assigned Counsellor";
    details.visitId = patientInfo.visitId;
    details.patientId = patientInfo.id;
    details.targetid = event.gvId;
    var ServerResVal = ServerRes;

    console.log("details to be sent in patientsection component:", details);

    let flag = "ReAssignCounsellor";
    dispatch(
      doctorActions.addConsultant(details, "Counsellor", flag, (resCb) => {
        if (resCb) {
          if (resCb.successMessages) {
            ServerResVal.success = "Counsellor Assigned Successfully.";

            // props.onModalClose(ServerResVal);
            getDoctorList();
            setServerRes({ ...ServerResVal });
            let info = {
              counselor: resCb.va.targetid,
              email: "",
              type: "edit",
              gvId: resCb.va.targetid,
            };

            setcounsInfo({ ...info });
            console.log("counsellor updated:", info);
          } else {
            ServerResVal.error = "Failed to Assign Counsellor.";
          }
          setServerRes({ ...ServerResVal });
          setTimeout(() => {
            closeAlert();
          }, 2000);
          console.log("resCbe : ", resCb);
        }
      })
    );
  };

  // }

  const handleBackClick = () => {
    this.props.history.push("/Home");
  };
  const closeModal = (event) => {
    setuploadPreview(!setuploadPreview);
    setCollapseAttachment(!collapseAttachment);
    console.log(" upload prevew closed ");
    //dispatch illige
    //  props.setState({flieUploadErrorMsg:''});
    //  props.setState({flieUploadMsg:''});
  };
  const closeMessageStrip = () => {
    console.log("closing msg strip fn in patientlist");
    setShowMsgStrip(!showMsgStrip);
  };

  const openAttachment = (event) => {
    setuploadPreview(!setuploadPreview);
    // props.closeAttachAction(false)
    dispatch(patientActions.setUploadedFiles(""));
  };

  const searchResult = (event) => {
    let searchvalue = JSON.parse(sessionStorage.getItem("searchvalue"));
    console.log("patientsearchstate", props);
    history.push({
      pathname: `/Home`,
      //   state: {
      //    patientsearch: props.patientsearchlist,
      //  },
    });
  };

  const updatePatientDetails = (event, payment) => {
    var ServerResVal = ServerRes;
    console.log("patient added successfully:", event);
    console.log("payemnt :", payment);
    if (event) {
      if (event.successMessages) {
        setServerRes({ ...ServerResVal });
        setMessageStrip({ success: event.successMessages[0] });
        setTimeout(() => {
          console.log("props:", props);
          let patientId;
          let visitId;
          if (event.patientresp.visit) {
            console.log("here here ");
            patientId = event.patientresp.visit.patientId;
            visitId = event.patientresp.visit.id;
          } else {
            console.log("edit ifcase");
            patientId = event.patientresp.patientId;
            visitId = event.patientresp.visitId;
          }

          let erpCustId = event.patientresp.erpCustId;
          let name = event.patientresp.fname;
          // props.history.push({
          //   pathname: `/PatientSections/${patientId}/${visitId}`,
          // })
          if (payment === "payment") {
            props.history.push({
              pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
            });
          }

          if (patientId && patientId != "" && visitId && visitId != "") {
            console.log("patient rech patient:.");
            getProcedureList(patientId, visitId);
            getVisitList(patientId, visitId);
            //setCallType("Add");
            //setPatientInfo({patientId:patId,visitId:vId })
          }
        }, 3000);
      } else {
        // setMessageStrip({ error: event.errorMessages[0] });
      }
    }
  };

  const openOnboardModal = (patientdetails) => {
    //props.openOnboardModal(patientdetails);
    setOnBoardModal(true);
  };
  const handlevisitSelect = (e) => {
    setSelecetdDate(e.target.value);
    setSelecetdDateVisit(e);
  };
  const handleSelect = () => {
    console.log("handleSelect:", selectedVisit);
    handleChange(selectedVisit);
  };

  const handleChange = (event, value) => {
    //appliedFilters.date = event.target.value ? true : false;
    //selectedDate = event.target.value ;
    console.log("event:",event);
    
    if(event !=null || event !=undefined ){

      let patId = props.match.params.patientId;
      let vId = event.target.value;
  
      console.log("event.target.value:", event.target.value);
      setSelecetdDate(event.target.value);
      if (event.target.value == "Select Visit Date") return;
      props.history.push({
        pathname: `/PatientSections/${patId}/${vId}`,
      });
      getProcedureList(patId, vId);
      getVisitList(patId, vId);
    }
    setViewVisit(false);
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };

  const updateInsuranceProcedure = (e) => {
    setInsuranceDesk(false);
    if (e) {
      setMessageStrip(e);
    }
  };
  const barcodeId = (number, width) => {
    console.log("barcodeId", typeof number);
    // const numberString = String(number);
    // const numberOfZeros = width - number.length;
    // if (numberOfZeros <= 0) {
    //   return numberString;
    // }
    // const paddedZeros = Array(numberOfZeros).fill('0').join('');
    // return paddedZeros + numberString;
    const numberString = number.toString();
    const zerosToAdd = Math.max(0, width - numberString.length);
    return "0".repeat(zerosToAdd) + numberString;
  };
  const setMessageStrip = (e) => {
    console.log("message obtained", e);
    var ServerResVal = ServerRes;
    if (e.success) {
      console.log("success message:", e.success);
      ServerResVal.success = e.success;
    } else {
      console.log("error message:", e.error);
      ServerResVal.error = e.error;
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  };
  const barcodeuac13 = barcodeId(props.match.params.visitId, 13);

  const str = patientInfo.fname + patientInfo.lname;
  const strlen = str.toString().slice(0, 8);
  console.log("canvas:", document.getElementById("mybarcode"));
  console.log("status:", patientInfo.status);
  const { inputRef } = useBarcode({
    value: barcodeuac13,
    options: {
      displayValue: true,
      background: "#F5F6F7",
    },
  });
  const openPayment = () => {
    // setShowPayment(true);
    console.log("patientInfo:", patientInfo);
    let patientId = patientInfo.id;
    let name = patientInfo.fname;
    let visitId = patientInfo.visitId;
    let erpCustId = patientInfo.erpCustId;
    props.history.push({
      pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
    });
  };

  const closePayment = () => {
    setShowPayment(false);
  };

  //   const getPDF =()=>{
  //     // dispatch(patientActions.getRegistrationPDF( (resCb) => {
  //     //     if (resCb) {
  //     //     }
  //     //   })
  //     // );
  //   //  console.log("getpdf")
  // //  // let fs = require('fs');
  // //   var path = '/uploads/Registraion_Fees_Invoice/'+registerPdfname;

  // //   const docDefinition = {
  // //     content: [
  // //       {
  // //         image: path,
  // //         width: 500
  // //       }
  // //     ]
  // //   };// Opens the PDF in a new tab in the browser
  // // "C:/Users/prola/Hms/redicare/hosp_src/public/uploads/Registraion_Fees_Invoice"
  // //const imageFile = '/uploads/Registraion_Fees_Invoice/'+registerPdfname ;

  // // Create a new FileReader instance
  // //const reader = new FileReader()

  // // Set up the onload function to create a data URL for the image
  // // reader.onload = () => {
  // //   const imageDataUrl = reader.result // This will contain the data URL for the image
  // //   // ... Use the imageDataUrl for your purposes
  // // }

  // // Read the image file as a data URL
  // //reader.readAsDataURL(imageFile);
  // // const docDefinition = {
  // //       content: [
  // //         {
  // //           image: reader.readAsDataURL(imageFile),
  // //           width: 500
  // //         }
  // //       ]
  // //     }
  // //     PdfPrinter.createPdf(docDefinition).open();
  // // const fs = require('fs');
  // // let pdffjf = '/uploads/Registraion_Fees_Invoice/'+registerPdfname;
  // // console.log("pdffilenrjkfou",pdffjf)
  // // const pdfFile = fs.readFileSync('/uploads/Registraion_Fees_Invoice/'+registerPdfname);

  // // const pdfBuffer = Buffer.from(pdfFile);
  // // const docDefinition = {
  // //   content: [
  // //   ]
  // // };
  // // docDefinition.content.push({ image: pdfBuffer, width: 500 });
  // // const imageFile = '/uploads/Registraion_Fees_Invoice/'+registerPdfname ;
  // // PdfPrinter.createPdf(docDefinition).getDataUrl((dataUrl) => {
  // //   const win = window.open();
  // //   win.document.write('<iframe src="' + dataUrl + '" width="100%" height="100%" frameborder="0"></iframe>');
  // // });

  // //PdfPrinter.createPdf(docDefinition).open();
  //   }

  const fetchPdf = async () => {
    console.log("registeredpdf", registerPdfname);
    // const url = "/uploads/Registraion_Fees_Invoice/" + registerPdfname; // Replace with the URL of the PDF file on your server
    const url = REG_INVOICE + registerPdfname;
    fetch(url)
      .then((response) => response.blob())
      .then((pdfBlob) => {
        console.log("blobbb", pdfBlob);

        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching PDF file:", error);
      });
  };

  const regCardPdf = () => {
    setRegistrationCard(true);
  };
  const tokenPrint = () => {
    setPrintTOkenModal(true);
    setTimeout(() => {
      setPrintTOkenModal(false);
    }, 500);
  };

  const toggleonboard = (event) => {
    setOnBoardModal(false);
  };
  const onValueChange = (value) => {
    setSelectedRadio(value);
    console.log("selectedOptionRadio", value);
  };
  const onboardPatient = (patient) => {
    var ServerResVal = ServerRes;
    let type = selectedRadio;
    console.log("type in log:", type);
    let visitListDetails = patientProcedureList;
    console.log("visitlist details in onboard", visitListDetails);
    // console.log("log in onboard method:", value);

    let admissionDateTime = "";
    if (type == "InPatient") {
      const dateTimeString = `${selecteddate} ${selectedTime}:00`;
      admissionDateTime = dateTimeString;
      console.log("timestamp", admissionDateTime);
    }
    dispatch(
      patientActions.onboardPatient(
        visitListDetails,
        type,
        admissionDateTime,
        (resCb) => {
          if (resCb) {
            console.log("onboardPatient response", resCb);
            if (resCb.successMessages) {
              let visitId = resCb.patientresp.visit.id;
              let patientId = resCb.patientresp.id;
              let name = resCb.patientresp.fname;
              let visitStatus = resCb.patientresp.visit.status;
              let erpCustId = resCb.patientresp.erpCustId;
              ServerResVal.success = "Patient onboarded successfully";
              setServerRes({ ...ServerResVal });
              setTimeout(() => {
                closeAlert();
              }, 3000);
              setTimeout(() => {
                if (visitStatus && visitStatus == "open") {
                  history.push({
                    pathname: `/PatientSections/${patientId}/${visitId}`,
                  });
                } else {
                  history.push({
                    pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
                  });
                }
              }, 2000);
            } else if (resCb.state === "error") {
              ServerResVal.error = "Patient onboard failed";
              setServerRes({ ...ServerResVal });
              setTimeout(() => {
                closeAlert();
              }, 3000);
            }

            setOnBoardModal(false);
            //renderVisitLists();
          }
        }
      )
    );
  };
  const downloadBarcode = (event) => {
    //event.preventDefault();
    const canvas = document.getElementById("mybarcode");
    console.log("canvas", canvas);
    const dataURI = canvas.toDataURL();
    // console.log(dataURI)
    var arrayURI = [];
    for (var i = 0; i < 6; i++) {
      // arrayURI.push({image:dataURI,width:250,height:50,marginBottom:10})
      arrayURI.push({
        columns: [
          { image: dataURI, width: 250, height: 50, marginBottom: 10 },
          // { image: dataURI, width: 250, height: 50, marginBottom: 10 },
        ],
      });
    }
    setarrayui(arrayURI);
    console.log("URI", arrayURI);
    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 55, 40, 55],
      pageOrientation: "portrait",
      defaultStyle: {
        fontSize: 12,
        columnGap: 20,
      },
      content: [arrayURI],
    };

    // PdfPrinter.createPdf(docDefinition).open();
    // pdfMake.createPdf(docDefinition).download()
  };

  const printAll = () => {
    console.log("printall clicked");
    // SetMergePdf(true);
    SetSeparatePdf(true);
    downloadBarcode();
    setVisitAction(false);
  };

  const createAuthToken = () => {
    let data = {};
    if (
      patientInfo &&
      patientInfo.patientId != null &&
      patientInfo.visitId != null
    ) {
      data.patientId = patientInfo.patientId;
      data.visitId = patientInfo.visitId;
      dispatch(
        patientActions.uploadToken(data, (resCb) => {
          if (resCb && resCb.oAuthAccessToken) {
            console.log("response of upload token api ", resCb);
            let authToken = resCb.oAuthAccessToken;
            scanDocument(authToken);
          }
        })
      );
    } else {
      console.log("PatientId or visitId null");
    }
  };

  const checkScanner = () => {
    dispatch(
      patientActions.checkScanner((resCb) => {
        if (resCb) {
          console.log("response of checkScanner api ", resCb);
          if (resCb.printer && resCb.printer == 1) {
            createAuthToken();
          } else {
            console.log("No scanners available");
          }
        }
      })
    );
  };

  const scanDocument = (authToken) => {
    dispatch(
      patientActions.scanDocument(authToken, (resCb) => {
        if (resCb) {
          console.log("response of scanDocument api ", resCb);
        }
      })
    );
  };

  const modalPopUp = () => {
    setModalopen(true);
    setAnchorEl(null);
  };
  const [modalopen, setModalopen] = useState(false);

  const convertToInPatient = () => {
    var ServerResVal = ServerRes;
    patientDetail.patientId = patientInfo.id;
    patientDetail.visitId = parseInt(patientInfo.visitId);
    patientDetail.closecomment = "CONVERTTOINPATIENT";
    dispatch(
      patientActions.updateVAStatus(patientDetail, (resCb) => {
        console.log("response close button:", resCb);
        if (resCb.respStatus == 0) {
          let type = "InPatient";
          console.log("type in log:", type);
          let visitListDetails = patientProcedureList;
          visitListDetails.onboardStatus = "CONVERTOUTTOINPATIENT";
          console.log("visitlist details in onboard", visitListDetails);
          let admissionDateTime = "";
          const dateTimeString = `${selecteddate} ${selectedTime}:00`;
          admissionDateTime = dateTimeString;
          console.log("timestamp", admissionDateTime);

          // console.log("log in onboard method:", value);
          dispatch(
            patientActions.onboardPatient(
              visitListDetails,
              type,
              admissionDateTime,
              (resCb) => {
                if (resCb) {
                  if(resCb.successMessages){
                    ServerResVal .success = "Outpatient converted to inpatient successfully";
                    setServerRes({ ...ServerResVal });
                  }
                  console.log("onboardPatient response", resCb);
                  let pateintId = resCb.patientresp.id;
                  let visitId = resCb.patientresp.visit.id;
                  setTimeout(() => {
                    props.history.push({
                      pathname: `/PatientSections/${pateintId}/${visitId}`,
                    });
                    getPatientProfile(pateintId, visitId);
                    getProcedureList(pateintId, visitId);
                    getVisitList(pateintId, visitId);
                    closeAlert();
                  }, 2000);
                }
              }
            )
          );
        }
      })
    );
    //setModalopen(false);
    // var ServerResVal = ServerRes;
    // console.log("convert to inpatient button clicked");
    // patientInfo.type = "InPatient";
    // let convert = "convertToInPatient";

    // let admissionDateTime = "";
    // const dateTimeString = `${selecteddate} ${selectedTime}:00`;
    // admissionDateTime = dateTimeString;
    // console.log("timestamp", admissionDateTime);

    // dispatch(
    //   patientActions.updatePatient(
    //     patientInfo,
    //     convert,
    //     admissionDateTime,
    //     (resCb) => {
    //       if (resCb) {
    //         console.log("Rescb", resCb.state, resCb.message);
    //         if (resCb.successMessages) {
    //           console.log("success message");
    //           ServerResVal.success =
    //             "Converted OutPatient to Inpatient successfully.";
    //           patientInfo.admsnDate = format(
    //             new Date(admissionDateTime),
    //             "MMM dd yyyy HH:mm"
    //           );
    //         } else if (resCb.state === "error") {
    //           ServerResVal.error = "Failed to convert OutPatient to Inpatient.";
    //         }
    //         setServerRes({ ...ServerResVal });
    //         setTimeout(() => {
    //           closeAlert();
    //         }, 3000);
    setModalopen(false);
    //       }
    //     }
    //   )
    // );
  };
  const toggle = (event) => {
    setModalopen(false);
  };
  const toggleVisitModal = () => {
    setViewVisit(false);
  };

  const [visitAction, setVisitAction] = useState(false);
  const [printAction, setPrintAction] = useState(false);
  const visitActions = (e) => {
    setVisitAction(true);
  };
  const [printOption, setPrintOption] = useState({
    token: true,
    barcode: true,
    receipt: true,
    idcard: true,
  });

  const printActions = (e) => {
    setPrintAction(true);
  };
  const closeDropDown = (e) => {
    if (visitAction) {
      setVisitAction(false);
    }
    if (printAction) {
      setPrintAction(false);
    }
  };

  const selectOption = (event, name) => {
    console.log("event:", event);
    // event.preventDefault();

    // event.nativeEvent.stopImmediatePropagation();
    // setVisitAction(true);
    let options = printOption;
    //  event.stopPropagation();
    console.log("option selcted:", event.target.checked);
    if (name == "token") {
      options.token = event.target.checked;
    } else if (name == "barcode") {
      options.barcode = event.target.checked;
    } else if (name == "idcard") {
      options.idcard = event.target.checked;
    } else {
      options.receipt = event.target.checked;
    }
    setPrintOption({ ...options });
  };

  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [schduleApp, setSchduleApp] = useState(false);
  const [viewCmplaint, setViewCmplaint] = useState(false);
  const [newCmplaint, setNewCmplaint] = useState(false);
  const [assignRoom, setAssignRoom] = useState(false);
  const [assignedRoomDet, setAssignedRoomDet] = useState();
  const [insuranceDesk, setInsuranceDesk] = useState(false);
  const [voucher, setVoucher] = useState(false);
  const [viewVisit, setViewVisit] = useState(false);

  const editPatientProfile = () => {
    setOpenPatientModal(!openPatientModal);
  };
  const closePatientForm = () => {
    setOpenPatientModal(false);
  };
  const scheduleAppointment = () => {
    setSchduleApp(!schduleApp);
  };
  const closeAppointmentModal = () => {
    setSchduleApp(false);
  };
  const viewComplaints = (e) => {
    setViewCmplaint(!viewCmplaint);
  };
  const newComplaints = (e) => {
    setNewCmplaint(!newCmplaint);
    setAnchorEl(null);
  };
  const assignRooms = (e) => {
    setAssignRoom(true);
    setAnchorEl(null);
  };
  const insuranceDeskDetails = (e) => {
    setInsuranceDesk(true);
    setAnchorEl(null);
  };
  const voucherInfo = (e) => {
    setVoucher(true);
    setAnchorEl(null);
  };
  const viewLastVisit = (e) => {
    setViewVisit(true);
    setAnchorEl(null);
  };
  const closeRoomModal = (e) => {
    setAssignRoom(false);
    setServerRes({ ...e });
    console.log("e", e);
    setTimeout(() => {
      closeAlert();
    }, 5000);
    getProcedureList(patientInfo.id, patientInfo.visitId);
  };
  const closeVoucher = (e) => {
    setVoucher(false);
  };
  const closeComplaintModal = (e) => {
    setViewCmplaint(false);
    setNewCmplaint(false);
    setServerRes({ ...e });
    console.log("e", e);
    setTimeout(() => {
      closeAlert();
    }, 5000);
    getProcedureList(patientInfo.id, patientInfo.visitId);
  };

  const getAssignedRoom = (data) => {
    console.log("each data : ", data);
    if (data && data.visitId) {
      // let patientData = data;
      // patientData['visitid'] = patientData.visitId;
      dispatch(
        doctorActions.getAssignedRoom(data, (resCb) => {
          if (resCb) {
            console.log("room assigned:", resCb);
            if (resCb.room && resCb.room.id) {
              setAssignedRoomDet(resCb.room);
              console.log("assigned room type:", resCb.room.type);
            }
          }
        })
      );
    }
  };

  const getFormateDate = (date) => {
    console.log("date passed:", date);
    if (date == "" || date == null) return;
    const reqdate = new Date(date);
    let formatedate = format(reqdate, "MMM dd yyyy");
    return formatedate;
  };

  const closePdfModal = (e) => {
    console.log("Print state:", e);
    SetSeparatePdf(false);
  };
  const handleOnChange = (value, event) => {
    if (value === "date") {
      setSelecteddate(event.target.value);
      console.log("Seleected Date is", event.target.value, event);
      console.log("Seleected Date is", typeof selecteddate);
    }
    if (value === "time") {
      setSelectedTime(event.target.value);
      console.log("Seleected Date is", event.target.value, event);
      console.log("Seleected Date is", typeof selectedTime);
    }
  };

  return (
    <main
      className="mainsection p-0 m-0"
      onClick={(event) => closeDropDown(event)}
    >
      {ServerRes.success && (
        <Alert severity="success" className="alert-div">
          {ServerRes.success}

          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(event) => closeAlert(event)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Alert>
      )}
      {ServerRes.error && (
        <Alert theme="error" className="alert-div">
          {ServerRes.error}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={(event) => closeAlert(event)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Alert>
      )}

      <div>
        <div className="patient-profile">
          <div className="p-3" style={{ backgroundColor: "#fff" }}>
            <div className="row p-details">
              <div className="col-lg-3 col-md-4 col-xs-1 col-sm-1 pr-0 cont">
                <div className="row">
                  {/* <img src="default.png" className="col-3 img-disp" style={{ width: `50px`, height: `50px` }}></img> */}
                  <span className="px-3 d-flex">
                    {/* <span className="mr-3" style={{fontSize:'20px'}}>{patientInfo.fname} {patientInfo.mname} {patientInfo.lname}</span> */}
                    {/* <Badge pill theme="secondary">{patientInfo.gender}</Badge> */}
                    {/* <Chip
                                label={patientInfo.gender}
                                className={classes.secondaryChip}
                               variant="outlined"
                               />         */}

                    {/* <Badge badgeContent={4} color="secondary">
                                    <MailIcon color="action" />
                              </Badge>     */}
                    <Badge badgeContent={patientInfo.gender} color="info">
                      <span className="mr-3" style={{ fontSize: "20px" }}>
                        {patientInfo.fname} {patientInfo.mname}{" "}
                        {patientInfo.lname}
                      </span>
                    </Badge>

                    <div class="px-31">
                      <div className="d-flex">
                        <span>{getFormateDate(patientInfo.dob)} </span>
                        <span className="ml-2">{patientInfo.age} yrs</span>
                      </div>
                    </div>
                  </span>

                  <div>
                    <p
                      className="mb-1 text-muted"
                      style={{ display: "inline" }}
                    >
                      Registered On: {regDate}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-xs-1 col-sm-1 row cont">
                <div className="col-lg-4 col-md-4 col-xs-1 col-sm-1 cont">
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">school</i>{" "}
                    <span className="p-data">
                      {patientInfo.education ? patientInfo.education : "NA"}
                    </span>
                  </p>
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">bloodtype</i>
                    <span className="p-data">
                      {patientInfo.bloodgroup ? patientInfo.bloodgroup : "NA"}
                    </span>
                  </p>
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">health_and_safety</i>
                    <span className="p-data">
                      {patientInfo.insurance == "YES"
                        ? "Insured"
                        : "Not Insured"}
                    </span>
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-1 col-sm-1 cont">
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">phone</i>{" "}
                    <span className="p-data">
                      {patientInfo.phno ? patientInfo.phno : "NA"}
                    </span>
                  </p>
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">mail</i>{" "}
                    <span className="p-data">
                      {patientInfo.email ? patientInfo.email : "NA"}
                    </span>
                  </p>
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">credit_card</i>{" "}
                    <span className="p-data">{patientInfo.adhar}</span>
                  </p>
                  {/* <p className="mb-0 d-flex"><i class="material-icons p-icon">numbers</i> <span className="p-data">{patientInfo.fileNo ? patientInfo.fileNo : 'NA'}</span></p> */}
                </div>
                {/* <div className='col-lg-4 col-md-4 col-xs-1 col-sm-1 cont'>
                        <p className='mb-0 d-flex'><i class="material-icons p-icon">file</i>  <span  className="p-data">{patientInfo.fileNo}</span></p>
                        <p className="mb-0 d-flex"><i class="material-icons p-icon">reference</i> <span className="p-data">{patientInfo.srcRef}</span></p>
                    </div> */}
                <div className="col-lg-4 col-md-4 col-xs-1 col-sm-1 cont">
                  <p className="mb-0 d-flex">
                    <i class="material-icons p-icon">place</i>&nbsp;
                    <span className="d-flex flex-column p-data">
                      <span>{patientInfo.address}</span>
                      <span>{patientInfo.area}</span>
                      <span>{patientInfo.city}</span>
                      <span>{patientInfo.state}</span>
                      <span>{patientInfo.pincode}</span>
                    </span>
                  </p>
                </div>

                {!activeSection.includes("Insurance") && (
                  <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex justify-content-end">
                    <Button
                      className="btn btn-submit btn-primary ml-3"
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={() => editPatientProfile()}
                    >
                      Edit Profile
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!activeSection.includes("Insuranc") && (
            <div className="my-4 mx-3 d-flex">
              <Card className="p-3 patient-summary col-lg-8 col-md-12 col-xs-12 col-sm-12">
                <span className="d-flex align-items-baseline justify-content-between flex-wrap">
                  <span className="col-lg-8 col-md-8 col-sm-12 col-xs-12 d-flex flex-lg-row flex-md-column flex-wrap align-items-baseline px-0">
                    <h4 className="mb-1  pl-0">Visit Summary&nbsp;</h4>
                    <p style={{ fontSize: "12px" }}>
                      {" "}
                      ({patientInfo.type}, &nbsp;
                      {getFormateDate(patientInfo.visitDate)}, &nbsp;
                      {patientInfo.status}&nbsp;
                      {isOverrided === true && ", Registration fee pending"})
                    </p>
                  </span>

                  <span className="mb-4 col-md-4 col-lg-4 col-sm-12 col-xs-12 d-flex justify-content-end">
                    {/* {patientInfo.status == "close" &&
                      currentStatus === null && (
                        <span className="mr-2">
                          <Button
                            className="btn-primary btn-block"
                            variant="contained"
                            color="primary"
                            sx={{ textTransform: "capitalize", height: "33px" }}
                            onClick={openOnboardModal}
                          >
                            Onboard
                          </Button>
                        </span>
                      )} */}
                    {patientInfo.status == "preReg" ||
                      (patientInfo.status == "booked" && (
                        <span className="mr-2">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={openPayment}
                          >
                            Pay Now
                          </button>
                        </span>
                      ))}

                    <span className="mr-2">
                      <div
                        class="printAction"
                        open={printAction}
                        toggle={(event) => printActions(event)}
                        group
                      >
                        {patientInfo.status == "close" &&
                          currentStatus === null && (
                            <Button
                              sx={{
                                textTransform: "capitalize",
                                height: "33px",
                                marginRight: "2px",
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                              className="btn-primary btn-block"
                              variant="contained"
                              color="primary"
                              onClick={openOnboardModal}
                            >
                              Onboard
                            </Button>
                          )}
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            height: "33px",
                            borderRadius: "0px",
                            // borderTopRightRadius: 0,
                            // borderBottomRightRadius: 0,
                          }}
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          onClick={checkScanner}
                        >
                          Scan
                        </Button>
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            marginLeft: "2px",
                            height: "33px",
                            borderRadius: 0,
                            borderBottomLeftRadius: 0,
                          }}
                          id="bornon"
                          variant="contained"
                          color="primary"
                          onClick={printAll}
                          aria-controls="print-menu"
                          aria-haspopup="true"
                        >
                          Print
                        </Button>

                        <Select
                          split
                          id="dr"
                          // onClick={e => e.stopPropagation()}
                        >
                          <MenuItem
                            className="pl-1"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <FormControlLabel
                              className=" p-0  "
                              onClick={(e) => e.stopPropagation()}
                              control={
                                <Checkbox
                                  className="pl-3"
                                  checked={printOption.token}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(event) =>
                                    selectOption(event, "token")
                                  }
                                />
                              }
                              label="Print Token"
                            />
                          </MenuItem>

                          <MenuItem
                            className="pl-1"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <FormControlLabel
                              className=" p-0  "
                              control={
                                <Checkbox
                                  className="pl-3"
                                  checked={printOption.barcode}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(event) =>
                                    selectOption(event, "barcode")
                                  }
                                />
                              }
                              label="Print Barcode"
                            />
                          </MenuItem>

                          <MenuItem
                            className="pl-1"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <FormControlLabel
                              className=" p-0  "
                              control={
                                <Checkbox
                                  className="pl-3"
                                  checked={printOption.receipt}
                                  onChange={(event) =>
                                    selectOption(event, "receipt")
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                />
                              }
                              label="Print Receipt"
                            />
                          </MenuItem>

                          <MenuItem
                            className="pl-1"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <FormControlLabel
                              className=" p-0  "
                              control={
                                <Checkbox
                                  className="pl-3"
                                  checked={printOption.idcard}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(event) =>
                                    selectOption(event, "idcard")
                                  }
                                />
                              }
                              label="Print IDCard"
                            />
                          </MenuItem>
                        </Select>
                      </div>
                    </span>
                    <>
                      <IconButton
                        id="ic1"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="small"
                        className="mr-2"
                      >
                        <MoreVertIcon id="we1" />
                      </IconButton>
                      <Menu
                        id="simple-menu "
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {activeSection.includes("Complaints") && (
                          <MenuItem onClick={(event) => newComplaints(event)}>
                            Complaints
                          </MenuItem>
                        )}
                        {activeSection.includes("AllocateRoom") &&
                          patientInfo.type === "InPatient" &&
                          patientInfo.status !== "close" && (
                            <MenuItem onClick={(event) => assignRooms(event)}>
                              Assign Rooms
                            </MenuItem>
                          )}
                        {activeSection.includes("Insurance") &&
                          patientInfo.type === "InPatient" && (
                            <MenuItem
                              onClick={(event) => insuranceDeskDetails(event)}
                            >
                              Insurance Desk
                            </MenuItem>
                          )}

                        {activeSection.includes("NonRedeemableVouchers") && (
                          <MenuItem onClick={(event) => voucherInfo(event)}>
                            Vouchers
                          </MenuItem>
                        )}

                        {patientInfo &&
                        patientInfo.status === "open" &&
                        patientInfo.type === "OutPatient" ? (
                          <MenuItem
                            onClick={modalPopUp}
                            // disabled={
                            //   patientInfo.internalStatus ===
                            //     "MOVED_TO_BILLING" ||
                            //   patientInfo.internalStatus ===
                            //     "INVOICE_GENERATED" ||
                            //   patientInfo.internalStatus === "BILL_CLEARED"
                            // }
                          >
                            Convert To Inpatient
                          </MenuItem>
                        ) : null}

                        <MenuItem onClick={(event) => viewLastVisit(event)}>
                          Last Visits
                        </MenuItem>

                        {patientInfo &&
                          (patientInfo.status === "preReg" ||
                            patientInfo.status === "booked" ||
                            isOverrided == true) && (
                            <MenuItem onClick={openPayment}>Pay Now</MenuItem>
                          )}
                      </Menu>
                    </>
                  </span>
                </span>

                <div className="row mainId">
                  <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                    <h6 className="mb-1">MAIN</h6>
                    <span className="d-flex align-items-center justify-content-between">
                      <span className="text-muted">{patientInfo.id}</span>
                    </span>
                  </div>
                </div>

                <div className="row">
                  {patientInfo.type == "InPatient" ? (
                    <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                      <h6 className="mb-1">Admission Date</h6>
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="text-muted">
                          {patientInfo.admsnDate}
                        </span>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {patientInfo.type == "InPatient" &&
                  patientInfo.dischargeDate != "" ? (
                    <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                      <h6 className="mb-1">Discharge Date</h6>
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="text-muted">
                          {patientInfo.dischargeDate}
                        </span>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {patientInfo.type == "OutPatient" ? (
                    <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                      <h6 className="mb-1">Token</h6>
                      <span className="d-flex align-items-center justify-content-between">
                        <span className="text-muted">
                          {patientInfo.tokenNumber}
                        </span>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {patientInfo.type == "OutPatient" ? (
                    <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12">
                      <h6 className="mb-1">Desk Number</h6>
                      <span className="text-muted">
                        {patientInfo.deskNumber}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row mx-0 mt-5 w-100">
                    {patientInfo && patientInfo.status != "close" ? (
                      <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                        <h6 className="mb-1">Bar Code</h6>
                        <span className="d-flex align-items-start">
                          <canvas
                            id="mybarcode"
                            ref={inputRef}
                            style={{ maxWidth: "150px" }}
                          />
                        </span>
                      </div>
                    ) : (
                      <canvas
                        id="mybarcode"
                        ref={inputRef}
                        style={{ maxWidth: "150px", display: "none" }}
                      />
                    )}

                    <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                      <h6 className="mb-1">Complaints</h6>

                      {complaintText && complaintText.length > 0 ? (
                        <>
                          <div className="complaints-det">
                            {complaintText.map((list, idx) => (
                              <>
                                <div
                                  key={idx}
                                  className="complaints-det my-2 justify-content-between"
                                  style={{ fontWeight: "400" }}
                                >
                                  <span title={list.text}>{list.text} </span>
                                </div>
                              </>
                            ))}
                          </div>
                          {complaintText.length > 2 && (
                            <div>
                              <a
                                class="more"
                                onClick={(e) => viewComplaints(e)}
                              >
                                view more
                              </a>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="text-muted">No complaints yet</div>
                      )}
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-6 col-sm-6">
                      <h6 className="mb-1">Room Allocated</h6>
                      {assignedRoomDet ? (
                        <div>
                          <p class="mb-0">
                            Floor Id: &nbsp;
                            <span className="text-muted">
                              {assignedRoomDet.floorId}
                            </span>
                          </p>
                          <p class="mb-0">
                            Name: &nbsp;
                            <span className="text-muted">
                              {assignedRoomDet.roomName},
                              {assignedRoomDet.description}{" "}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="text-muted">No Rooms Assigned</div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
              <div className="appointmnt-det col-lg-4 col-md-12 col-xs-12 col-sm-12 d-flex flex-column justify-content-between px-sm-2 px-md-0 px-lg-2">
                <Components.SearchDocList
                  docInfo={docInfo}
                  from="patienSection"
                  patientInfo={patientInfo}
                  patientCalenderInfo={patientCalenderInfoD}
                  lastassigned={lastAsssigned}
                  parentCallBack={(event) => handleDocChange(event)}
                  onModalClose={(event) => updatePatientDetails(event)}
                />

                <Components.Counsellor
                  from="patienSection"
                  counsInfo={counsInfo}
                  patientInfo={patientInfo}
                  patientCalenderInfo={patientCalenderInfoC}
                  lastassigned={lastAsssigned}
                  parentCallBack={(event) => handleCounslChange(event)}
                  onModalClose={(event) => updatePatientDetails(event)}
                />
              </div>
            </div>
          )}

          {activeSection.includes("Insurance") && (
            <>
              <canvas
                id="mybarcode"
                ref={inputRef}
                style={{ maxWidth: "150px", display: "none" }}
              />

              <Components.InsuranceProcedureListing
                patientProcedureList={patientProcedureList}
                patientInfo={patientInfo}
                addInsurance="true"
                allVisitdates={allVisitdates}
                selectedDate={selectedDate}
                handlevisitChange={(e) => handleChange(e)}
                onClose={(event) => updateInsuranceProcedure(event)}
              />
            </>
          )}

          <Dialog
            className="d-flex w-95 h-90 flex-column-reverse "
            id="closemodalopen"
            open={modalopen}
            toggle={toggle}
          >
            <div className="w-95 h-120">
              <DialogTitle>
                Convert To InPatient
                <IconButton
                  aria-label="close"
                  onClick={toggle}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <span>
                  Are you sure want to convert OutPatient to InPatient?
                </span>
                <div className="d-flex m-2">
                  <div>
                    <label style={{ paddingTop: "5px" }}>
                      Select a Admission Date:
                    </label>
                    <div className="d-flex pl-0 col-lg-7 col-xl-7 col-md-12 col-12">
                      {/* Date Input */}
                      <div className="mr-2" style={{ marginRight: "20px" }}>
                        <input
                          sx={{ marginRight: "10px" }}
                          name="admissionDate"
                          id="admissionDate"
                          className="form-control filterin Quantity"
                          placeholder="Enter Scheduled Date"
                          required
                          type="date"
                          defaultValue={selecteddate}
                          onChange={(event) => handleOnChange("date", event)}
                        />
                      </div>
                      {/* Time Input */}
                      <div className="ml-2" sx={{ marginLeft: "10px" }}>
                        <input
                          name="admissionTime"
                          id="admissionTime"
                          className="form-control filterin Quantity"
                          placeholder="Enter Scheduled Time"
                          required
                          type="time"
                          defaultValue={selectedTime}
                          onChange={(event) => handleOnChange("time", event)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  className="btn btn-submit btn-primary btn-style bottomright"
                  onClick={convertToInPatient}
                >
                  Convert
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          {/* <Dialog
      open={modalopen}
      onClose={toggle}
      className="d-flex flex-column-reverse"
    >
      <DialogTitle>
        Warning!
        <IconButton
          aria-label="close"
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <span>
          Are you sure you want to convert OutPatient to InPatient?
        </span>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={convertToInPatient}
          sx={{textTransform:'none'}}
        >
          Convert
        </Button>
      </DialogActions>
    </Dialog> */}

          {/* Display Last Visit Modal */}

          <Dialog
         
            className="d-flex w-95  flex-column-reverse h-180 modalDialog1"
            id="visitModal"
            open={viewVisit}
            toggle={toggleVisitModal}
          
          >
            <div>
              <DialogTitle>
                View Last Visits
                {/* <span className="close-model" onClick={() => toggleVisitModal()}>
                X
              </span> */}
                <IconButton
                  aria-label="close"
                  onClick={() => toggleVisitModal()}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent   >
                <div>
                  <Components.VisitList
                
                    allVisitdates={allVisitdates}
                    selectedDate={selectedDate}
                    handlevisitChange={(e) => handlevisitSelect(e)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                {/* <Button
               variant="contained"
               color="primary"
              // className="btn btn-submit btn-primary btn-style bottomright"
                
                onClick={() => handleSelect()}
              >
                OK 
              </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSelect}
                  sx={{ textTransform: "none" }}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>

      <Dialog
        className="d-flex w-95 h-90 flex-column-reverse Dialog11"
        id="closemodalopen"
        open={onBoardmodal}
        toggle={toggleonboard}
      >
        <div>
          <DialogTitle>
            Select Patient Category
            {/* <span
                  className="close-model"
                  onClick={(event) => toggleonboard(event)}
                >
                  X
                </span> */}
            <IconButton
              aria-label="close"
              onClick={toggleonboard}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <span>Are you sure to OnBoard a patient?</span>
          </DialogContent>
          <DialogActions className="d-flex flex-column align-items-end ">
            <RadioGroup
              row
              aria-label="patientCategory"
              name="patientCategory"
              value={selectedRadio}
              onChange={(event) => onValueChange(event.target.value)}
              className="d-flex w-100 justify-content-between "
            >
              <FormControlLabel
                value="InPatient"
                control={<Radio />}
                label="InPatient"
              />
              <FormControlLabel
                value="OutPatient"
                control={<Radio />}
                label="OutPatient"
              />
              <FormControlLabel
                value="OnlinePatient"
                control={<Radio />}
                label="OnlinePatient"
              />
            </RadioGroup>

            <Button
              variant="contained"
              color="primary"
              className="btn-style bottomright"
              onClick={onboardPatient}
              style={{ textTransform: "none" }}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {openPatientModal && (
        <Components.PatientForm
          patientInfo={patientInfo}
          deskDetails={deskDetails}
          openPatientForm={openPatientModal}
          type="AdminHomeView"
          openOnboardModal={(event) => openOnboardModal(event)}
          closePatientForm={(event) => editPatientProfile(event)}
          onSuccess={(event) => updatePatientDetails(event)}
        />
      )}

      {/* {schduleApp &&  (
            <Components.AppointmentScheduleModal
              from="list"
              counsInfo={counsInfo}
              type="Counsellor"
              patientData={patientInfo}
            
              onModalClose={(event) => closeAppointmentModal(event)}
            />
         
          )} */}

      {viewCmplaint && (
        <Components.Complaints
          patientInfo={patientInfo}
          viewComplaint={viewCmplaint}
          allVisitdates={allVisitdates}
          selectedDate={selectedDate}
          handlevisitChange={(event) => handleChange(event)}
          onCloseComplaintModal={(event) => closeComplaintModal(event)}
        />
      )}
      {newCmplaint && (
        <Components.Complaints
          patientInfo={patientInfo}
          newComplaint={newCmplaint}
          allVisitdates={allVisitdates}
          selectedDate={selectedDate}
          handlevisitChange={(event) => handleChange(event)}
          onCloseComplaintModal={(event) => closeComplaintModal(event)}
        />
      )}
      {assignRoom && (
        <Components.Room
          patientInfo={patientInfo}
          assignRoom={assignRoom}
          allVisitdates={allVisitdates}
          selectedDate={selectedDate}
          handlevisitChange={(event) => handleChange(event)}
          closeRoomModal={(event) => closeRoomModal(event)}
        />
      )}

      {insuranceDesk && (
        <Components.InsuranceProcedureListing
          patientProcedureList={patientProcedureList}
          patientInfo={patientInfo}
          addInsurance={insuranceDesk}
          onClose={(event) => updateInsuranceProcedure(event)}
        />
      )}

      {voucher && (
        <Components.Vouchers
          addVouchers={voucher}
          onCloseVoucher={(event) => closeVoucher(event)}
        />
      )}
      {separatePdf && (
        <div className="">
          <Components.SeparatePdf
            patientDetails={patientInfo}
            deskDetails={deskDetails}
            tokenDetails={patientInfo}
            barcode={arrayui}
            printOption={printOption}
            onModalClose={(event) => closePdfModal(event)}
          />
        </div>
      )}
    </main>
  );
}

export default PatientSections;
