import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Components from "./index";
import { patientActions } from "../../core/actions";
import MessageStrip from '../../shared/messagestrip/MessageStrips';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { Row, Col,Button } from "shards-react";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { doctorActions } from '../../core/actions';
// import {FormInput} from "shards-react";
import { helperServices } from '../../shared/helper';
import debounce from 'lodash.debounce';
//import XLSX from 'xlsx-style';
import Papa from 'papaparse';
import { Add, GetApp } from '@material-ui/icons'; 
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {  FormControlLabel, Radio, RadioGroup } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

function AdminHomeView(props) {
  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [showPatientDetail, setshowPatientDetail] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [showList, setShowList] = useState(false);
  const [patient, setPatient] = useState({page:1,pageSize:50});
  const [patientSearchList, setPatientSearchList] = useState([]);
  const [showClose, setShowClose] = useState(false);
  const [openAddPatientModal, setOpenAddPatientModal] = useState(false);
  const [reportDownload, setReportDownload] = useState(false);
  const [patientType, setPatientType] = useState('InPatient'); 
  const today = new Date().toISOString().split('T')[0];
  //const [dateFrom, setDateFrom] = useState('');
  //const [dateTo, setDateTo] = useState('');
  //const [patientListCollect, setPatientListCollect] = useState([]);
  const [outpatientChecked, setOutpatientChecked] = useState(false);
  const [inpatientChecked, setInpatientChecked] = useState(true); 
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const maxDate = sevenDaysAgo.toISOString().split('T')[0];
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  const [dateFrom, setDateFrom] = useState(formatDate(sevenDaysAgo));
  const [dateTo, setDateTo] = useState(formatDate(new Date()));

  const dispatch = useDispatch();
  window.onunload = function () {
    sessionStorage.removeItem('searchvalue');
  }

  useEffect(() => {
    let searchvalue = JSON.parse(sessionStorage.getItem('searchvalue'));
    console.log("SearchInput", searchvalue);
    if (searchvalue) {
      setShowClose(true);
      setSearchText(searchvalue);
      searchPatientResult(searchvalue);
    }
    let data = {}
    data['type'] = 'mypatient';
    console.log("prosps on pl s :", props);
  }, []);
 
  const handleClick = (pDetail, isAddNew) => {
    console.log("selected patient:", pDetail);
    
    if (isAddNew === true) {
      console.log("isAddNew:", isAddNew);
      setAddNew(true)
      setOpenAddPatientModal(true)
      setshowPatientDetail(true);
      dispatch(patientActions.setPatientDetails(pDetail))
      //Navigating to Patient Section
      // setTimeout(() => {
      //   props.history.push({
      //     pathname: `/PatientSections/new`,
      //   });
      // }, 200);
    }
    else {
      setAddNew(false);
      dispatch(patientActions.setPatientDetails(pDetail))
      setshowPatientDetail(true);
      //Navigating to Patient Section
      setTimeout(() => {
        let patientId = pDetail.visit.patientId;
        let visitId = pDetail.visit.id;
        props.history.push({
          pathname: `/PatientSections/${patientId}/${visitId}`,
          /* state: {
            patientDetail: patientsDetails,
          }, */
        });
      }, 200);
    }
    console.log("Add new : ", addNew);
    console.log("pDetail : ", pDetail);
  }
  //const patientsDetails = useSelector(state => state.patientDetailsState.patient)
  window.onunload = function () {
    sessionStorage.removeItem('searchvalue');
   
  }

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  const handleInputChange = (event) => {
    console.log("event is ", event);
    if (event && event.target && event.target.value && event.target.value.length && event.target.value.length >= 3) {
      setLoading(true);
      setShowClose(true);
    }
    else{
      setLoading(false);
    }
    console.log("spinloading", loading);
    let value = event.target.value;
    validateAndChange(value)
    // sessionStorage.setItem('searchvalue', JSON.stringify(value));
   // event.stopPropagation();
  }

  const validateAndChange = (value) => {
    let nullarray = [];
    setSearchText(value);
    if (value.length < 3) {
      setSearchText(value);
      setShowMsgStrip(false);
     
    }
    if (!value) {
      // messageDetails.messageStrip='';
      // setPatientSearchList([...nullarray])
      setSearchText(value);
      console.log("patientsearchlist null array !value : ", value, patientSearchList)
      setShowMsgStrip(false);
    }
    //  else {
    if (value.length >= 3) {
      setSearchError("");
      setSearchText(value);
      // handleSearch(value);
      // setShowList(true);
      let search = { patient: value };
      console.log("search input : ", value);
      debounceSearchPatientResult(value)
    }
    sessionStorage.setItem('searchvalue', JSON.stringify(value));

    // }

  }

  const getFullName = (fname, mname, lname) => {
    var fullName = '';
    console.log("fname : ", fname);
    console.log("mname : ", mname);
    console.log("lname : ", lname);
    if (!fname || fname == null) { fname = '' }
    if (!mname || mname == null) { mname = '' }
    if (!lname || lname == null) { lname = '' }
    var pfname = fname
    console.log("pfname : ", pfname);
    //   .toLowerCase();
    var pmname = mname
    console.log("pmname : ", pmname);
    //   .toLowerCase();
    var plname = lname
    console.log("plname : ", plname);
    //   .toLowerCase();
    if (pfname == null) { pfname = '' }
    if (pmname == null) { pmname = '' }
    if (plname == null) { plname = '' }
    pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1)
    pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1)
    plname = plname.charAt(0).toUpperCase() + plname.slice(1)
    if (fname && lname) {
      fullName = pfname + ' ' + plname;
      console.log("fullName : ", fullName);
    } else if (fname && mname) {
      fullName = pfname + ' ' + pmname;
      console.log("fullName : ", fullName);
    } else if (fname && mname && lname) {
      fullName = pfname + ' ' + pmname + ' ' + plname;
      console.log("fullName : ", fullName);
    } else {
      fullName = pfname + "      ";
      console.log("fullName : ", fullName);
    }
    return fullName;
  }
  const overlayNoRowsTemplate= "No record found";
  const searchPatientResult = (data) => {
    setMessageDetails({ message: '' });

    dispatch(patientActions.patientSearch(data, resCb => {
      if (resCb) {
        console.log("patient search resCb name in patient list : ", resCb)
        // messageStrip
        if (resCb.state == "success") {
          let patientInfo = resCb;
          let docInfo;
          if (resCb.personmap) {
            Object.keys(resCb.personmap).forEach((key) => {
              console.log("key ", key);
              docInfo = {
                first_name: resCb.personmap[key].first_name,
                id: resCb.personmap[key].personId,
                email: resCb.personmap[key].email,
              }
            })
          }
          let searchList = [];
          console.log("docInfo obtained:", docInfo);
          dispatch(doctorActions.setDoctorInfo(docInfo));
          if (resCb.patientListCollection.length > 0) {
            setMessageDetails({ message: '' });
            for (let pat of resCb.patientListCollection) {
              console.log("patientInfo.fname, patientInfo.mname, patientInfo.lname in patient list : ", pat.fname, pat.mname, pat.lname)
              pat["fullName"] = getFullName(pat.fname, pat.mname, pat.lname)
              console.log("patient full name in patient list : ", pat)
            }


            Object.keys(resCb.patientListCollection).forEach((key) => {
              if (resCb.patientListCollection[key] != null) {
                console.log("resCb.patientListCollection", resCb.patientListCollection[key]);
                searchList.push(resCb.patientListCollection[key]);
                setPatientSearchList([...searchList]);
              } 
            });
            setPatientSearchList([...searchList]);

          } else {
            setShowList(false);
            
            setPatientSearchList([]);
            
            setMessageDetails({error: 'error',
            message: resCb.messageStrip});

          }
          setLoading(false);
          setShowMsgStrip(true);
          setMessageDetails({
            message: resCb.messageStrip,
            success: 'success'
          });
          console.log("Patientlist messageDetails success : ", messageDetails)
        } else {
          setShowMsgStrip(true);
          
          // setMessageDetails({message: resCb.messageStrip});
            setMessageDetails({error: 'error',
            message: resCb.messageStrip});
          console.log("Patientlist messageDetails error :",messageDetails)
        }

        console.log("response from api : ", resCb);
      }
    }))
  }

  const debounceSearchPatientResult = useCallback(
    debounce(searchPatientResult, 500)
  , []);
 
  console.log("loading true", loading)
  const closeMessageStrip = () => {
    console.log("closing msg strip fn in patientlist");
    setShowMsgStrip(!showMsgStrip);

  }

  const clearSearch = (event) => {
    setSearchText("");
    setLoading(false);
    setShowClose(false);
  }
  const closePatientFormModal = () => {
   setOpenAddPatientModal(false)
   setAddNew(false);

  }
 

  const updatePatientDetails = (event, payment) => {
    // var ServerResVal = ServerRes;
    console.log("patient added successfully:", event);
    if (event) {
      if (event.successMessages) {
        setMessageDetails({
          message: event.successMessages[0],
          success: 'success'
        });
       
        setTimeout(() => {
          console.log("props:", props);
          let patientId;
          let visitId;
          if (event.patientresp.visit) {
            patientId = event.patientresp.visit.patientId;
            visitId = event.patientresp.visit.id;
          }

          let erpCustId = event.patientresp.erpCustId;
          let name = event.patientresp.fname; 
          // props.history.push({
          //   pathname: `/PatientSections/${patientId}/${visitId}`,
          // })
          if (payment === "payment") {
            props.history.push({
              pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
            });
          }

          // if (patientId && patientId != "" && visitId && visitId != "") {
          //   getProcedureList(patientId, visitId);
          //   getVisitList(patientId, visitId);
           
          // }
        }, 3000);
      } else {
         setMessageDetails({
          message: event.successMessages[0],
          error: 'error'
        });
        // setMessageStrip({ error: event.errorMessages[0] });
      }
    }
  };
  const handleReportDownload = () =>{
    setReportDownload(!reportDownload);
    // dispatch(patientActions.patientSearch(" ", resCb => {
    //   if (resCb.patientListCollection) {
    //     setPatientListCollect(resCb.patientListCollection);
    //     console.log("patient search in report download : ", resCb.patientListCollection)
    //   }
    // }))
  }
  const handleOutpatientChange = () => {
    setOutpatientChecked(!outpatientChecked);
  };

  const handleInpatientChange = () => {
    setInpatientChecked(!inpatientChecked);
  };
  const handlePatientTypeChange = (value) => {
    setPatientType(value);
  };
  
  const handleDateFromChange = (e) => {
    setDateFrom(e.target.value);
  };

  const handleDateToChange = (e) => {
    setDateTo(e.target.value);
  };

  const handleDownload = () => {
  let data = {
    dateFrom: dateFrom,
    dateTo: dateTo,
    text: " "
  };
  
   dispatch(patientActions.patientSearch(data, resCb => {
      if (resCb.patientListCollection) {
        console.log("patient search in report download : ", resCb.patientListCollection)
      
    const typePatient = patientType; 
    const fromDate = dateFrom;
    const toDate = dateTo;
    console.log("typePatienttypePatient",typePatient,fromDate,toDate)
   var newpatientReport = [];
  
    const filtered =resCb.patientListCollection.filter((patient) => {
      console.log("patient.visitList",patient.visitList.length);
      console.log("patient.visitList",patient.visitList);
      console.log("Patientid :",patient.id);
      return patient.visitList.some((visit) => {
        const visitDate = new Date(visit.visitDate).getTime();
        console.log("visit.visitDate",visit.visitDate, fromDate, toDate);
        console.log("visitDate",visitDate);
        console.log("new Date(fromDate).getTime()",new Date(fromDate).getTime());
        console.log("new Date(toDate).getTime()",new Date(toDate).getTime());
        console.log('visit.type:', visit.type);
        console.log('typepatient:', typePatient);
        
        return (
          ((visitDate >= new Date(fromDate).setHours(0, 0, 0, 0)) &&
          (visitDate <= new Date(toDate).setHours(23, 59, 59, 999)) &&
          (visit.type === typePatient))
        );
      });
    });


   
    console.log("patientListCollect after processing",filtered);
    filtered.forEach(item => {
      const uniqueIds = {};
        const filteredData = item.visitList.filter(item => {
          console.log("item.main :", item.patientId);
          // If the ID is not in the uniqueIds object, add it and keep the item
          if ((!uniqueIds[item.Id])&&(item.type === typePatient)) {
            uniqueIds[item.Id] = true;
            return true;
          }
          // If the ID is already in the uniqueIds object, skip the item
          return false;
        });
        filteredData.forEach(vi =>{
        const date = new Date(vi.visitDate);
        const options = { year: 'numeric', month: 'long', day: '2-digit' };
        const formattedVisitDate = date.toLocaleDateString('en-US', options);
          if (item.person) {
          newpatientReport.push({'MAIN':item.id, 'Name':item.fname+" "+ item.mname+" "+item.lname,'Phone No':item.phno,'Doctor Name':item.person.first_name+" " +item.person.last_name,'Visit Id':vi.id,'Visit Type':vi.type,'Visit Date':formattedVisitDate})
          }else{
          newpatientReport.push({'MAIN':item.id,'Name':item.fname+" "+ item.mname+" "+item.lname,'Phone No':item.phno,'Doctor Name':"",'Visit Id':vi.id,'Visit Type':vi.type,'Visit Date':formattedVisitDate})
          }
        })
       
     console.log("filteredDataghjghk",filteredData)
     });

     console.log("newpatientReport",newpatientReport);
     const sortePateintdData = newpatientReport.sort((a, b) => a.main - b.main);
     // Convert the newpatientReport array to a CSV string
   const csvData = Papa.unparse(sortePateintdData);

    // Create a Blob from the CSV string
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and create a download link
    link.href = window.URL.createObjectURL(blob);
    link.download = typePatient+'_report.csv';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
 
  }
}))
 
   // setPatientReport(newpatientReport);
    
  };

  return (
    <main className="mainsection list-patient">
    {showMsgStrip ?
      <MessageStrip
        msgStrip={messageDetails}
        closeMessageStrip={(event) => closeMessageStrip(event)}
      />
      : ''}
    {/* Page Header */}
    {/* <Row noGutters className="page-header mb-3 mx-2"> */}
    <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      marginBottom: 3,
      marginLeft: 2,
      marginRight: 2,
      
    }}
  >
      {/* <Col className='col-md-10 mt-2'>  */}
      <Grid container justifyContent="space-between" alignItems="baseline" >
        
      <Grid item xs={12} md={10} sx={{ mt: 2 ,display: "flex", justifyContent: "center", alignItems: "center" }}  >
        <form className="main-navbar__search w-100 d-md-flex d-lg-flex  " onSubmit={(event) => handleSubmit(event)}>
          <div className="input-group input-group-seamless ">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{ color: `#111b21` }} className="material-icons">search</i>
              </span>
            </div>
            
             <TextField 
             className="navbar-search mb-2 menu-search bg-white custom-width"
             placeholder="Search Patients By Name / PhoneNumber / MAIN....."
             autoFocus
             value={searchText}
             type="search"
             onChange={handleInputChange}
             InputProps={{
              style: { paddingLeft: '15px',height:"35px",display: "flex", justifyContent: "center", alignItems: "center" }, // Adjust padding as needed
              
            }}
             />
           

          </div>
        </form>
        </Grid>

      {helperServices.CheckAccessLevel("patients") &&
        
        // <Col className='col-md-2 text-center '>
<Grid item xs={12} sm={6} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="text-center buttonsetconfirm">
          {/* <Button
            type="submit"
            className="newPatient"
            onClick={(event) => handleClick("", true)}
          >
            Add New Patient
          
            </Button> */}
            <div className="button-container">
          
         <Button
    type="submit"
    variant="contained"
    className="newPatient"
    style={{ padding: '5px 10px', fontSize: '12px', marginRight: '20px', marginLeft: '30px' }}
    onClick={(event) => handleClick('', true)}
  >
    <AddIcon style={{ marginRight: '2px' }} />
    <span className="button-text">Add New Patient</span>
  </Button>
         </div>
         <div className="button-container">

      <Button
        variant="contained"
        color="primary"
        type="submit"
        className="downloadReport"
        style={{ padding: '5px 10px', fontSize: '12px' }}
        onClick={(event) => handleReportDownload()}
      >
        <GetApp style={{ marginRight: '5px' }} /> 
        <span className="button-text">Download Report</span>
      </Button>
    </div>
         
        </Grid>
      }
        </Grid>

       
      </Box>

   


    {searchError && (
      <div style={{ color: "#EC4147", fontSize: "12px" }}>
        <p>
          <span>{searchError}</span>
        </p>
      </div>
    )}

    <div className="main-content-container container-fluid cont">
      <div className="row">
        <div className="mb-12 col-sm-12 col-md-12 hpcol bg">
          {showList === true ? (
            <div className="blog-comments card pcard">
              <div className="p-0 card-body">
                {patientSearchList &&
                  patientSearchList.length > 0 &&
                  patientSearchList.map((currPatObj, idx) => (
                    <div
                      className="blog-comments__item d-flex p-1 cpointer"
                      key={idx}
                      onClick={(event) =>
                        handleClick(currPatObj, false)
                      }
                    >
                      <div className="blog-comments__content">
                        <div className="blog-comments__meta">
                          <div className="text-dark">
                            {/* {patientDetail.fname +
                                " " +
                                patientDetail.mname +
                                " " +
                                patientDetail.lname} */}
                            {currPatObj.fullName}
                          </div>
                          <p className="m-0 text-muted">
                            Age : {currPatObj.age} Years Adhar
                            :{currPatObj.adhar}
                            &nbsp;Phone: {currPatObj.phno}
                          </p>
                          <p className="m-0 text-muted">
                            {currPatObj.area}
                            {currPatObj.city}
                            {currPatObj.state}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        </div>
      </div>
   

    <Components.PatientListing
      searchText={searchText}
      patientsearchlist={patientSearchList}
      parentCallBack={(event) => clearSearch(event)}
     

    />

    {addNew && (
      <Components.PatientForm
                  patientInfo={[]}
                  openPatientForm={openAddPatientModal}
                  type="AdminHomeView"
                  closePatientForm={() => closePatientFormModal()}
                  onSuccess={(event) => updatePatientDetails(event, "payment")}
                  
                 
      />
    )} 
{reportDownload && (
<div>
  




      {/* ///// */}


      <Dialog open={reportDownload} onClose={handleReportDownload} id='Download_report1'>
      <DialogTitle  onClose={handleReportDownload}>Download Report</DialogTitle>
      <IconButton
      aria-label="close"
      onClick={handleReportDownload}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom:"20px" }}>
          <div style={{ flex: 1 }}>
            <FormControlLabel
              control={
                <Radio
                  value="OutPatient"
                  checked={patientType === 'OutPatient'}
                  onChange={() => handlePatientTypeChange('OutPatient')}
                />
              }
              label="Outpatient"
            />
          </div>
          <div style={{ flex: 1 }}>
            <FormControlLabel
              control={
                <Radio
                  value="InPatient"
                  checked={patientType === 'InPatient'}
                  onChange={() => handlePatientTypeChange('InPatient')}
                />
              }
              label="Inpatient"
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <TextField
              type="date"
              value={dateFrom}
              defaultValue={maxDate}
              onChange={handleDateFromChange}
              max={today}
              label='Date From'
              fullWidth
            />
          </div>
          <div style={{ flex: 1, marginLeft: '8px' }}>
            <TextField
              type="date"
              value={dateTo}
              defaultValue={today}
              onChange={handleDateToChange}
              max={today}
              label='Date To'
              fullWidth
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginTop: '20px', textAlign: 'center'}}>
          <Button onClick={handleDownload} variant="contained" color="success" sx={{textTransform:'capitalize' }}>
            Download
          </Button>
        </div>
      </DialogActions>
    </Dialog>
      {/* ///// */}
</div>
)}


  </main>

);


}


/*
function mapStateToProps(state) {

 console.log('state : ',state)
return {
  patientDetail: state.patientDetailsState.patientDetail,
  patient: state.patientDetailsState.patient,
};
}

function mapDispatchToProps(dispatch) {
return {
  getAllPatientInfo: (searchText, resCb) =>
    dispatch(patientActions.getAllPatientInfo(searchText, resCb)),
  setPatientDetails: (val) =>
    dispatch(patientActions.setPatientDetails(val)),
  getVisitInfo: (resCb) => dispatch(patientActions.getVisitInfo(resCb)),
};
} 

const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(PatientList);
*/
/* 
function mapStateToProps(state) {
return {
  patientDetail: state.patientDetailsState.patientDetail,
  patient: state.patientDetailsState.patient,
};
}
function mapDispatchToProps(dispatch) {
return {
  getAllPatientInfo: (searchText, resCb) =>
    dispatch(patientActions.getAllPatientInfo(searchText, resCb)),
  setPatientDetails: (val) =>
    dispatch(patientActions.setPatientDetails(val)),
  getVisitInfo: (resCb) => dispatch(patientActions.getVisitInfo(resCb)),
};
} 

export default connect(mapStateToProps, mapDispatchToProps)(PatientList); 

function mapDispatchToProps(dispatch) {
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
*/
export default AdminHomeView;
