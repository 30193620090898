import React, { useState, useEffect } from "react";
import { format } from "date-fns";

function VisitList(props) {
  const [selectedDate, setSelectedDate] = useState();
  const [allVisitdates,setallVisitdates] =useState([]);

  useEffect(() => {
    console.log("------props in visit list---------", props);
    setSelectedDate(props.selectedDate);
    handleallVisitdates();
  }, [props]);

  const handleallVisitdates=()=>{
    if (props.allVisitdates) {
      props.allVisitdates.forEach((lastVisited) => {
        if (lastVisited.internalStatus !== "NotVisited") {
          allVisitdates.push(lastVisited);
        }
      });
    }
  }

  const handleVisitSelect = (e) => {
    // setSelectedDate(e.target.value);
    // selectedDate = e.target.value
    console.log("handleVisitSelect", e);
    {
      props.handleVisitSelect
        ? props.handleVisitSelect(e)
        : props.handlevisitChange(e);
    }

    //    props.handlevisitChange(e);
  };

  const getFormateDate = (date, type) => {
    console.log(date);
    //  procedureDate = date;
    let formatedate;
    if (type === "discharge") {
      let date1 = date.split("T");
      formatedate = format(new Date(date1[0]), "MMM dd yyyy");
      console.log("discharge date", formatedate);
    } else {
      const reqdate = new Date(date);
      // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min;
      formatedate = format(reqdate, "MMM dd yyyy");
    }
    return formatedate.toString();
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex justify-content-end align-items-center">
        {/* <div className='col-6 d-flex align-items-center'> */}
        <span className="col-3">Select Date </span>
        <select
          name="Select Date"
          value={selectedDate}
          className="form-control VisitList"
          onChange={(e) => handleVisitSelect(e)}
        >
          {allVisitdates &&
            allVisitdates.map((lastVisited, index) => (
              <option key={index} value={lastVisited.id}>
                {lastVisited &&
                lastVisited.visitDate &&
                lastVisited.type == "InPatient"
                  ? `AD - ${getFormateDate(lastVisited.visitDate)} `
                  :  `VD - ${getFormateDate(lastVisited.visitDate)} `}

                {" - "}
                {lastVisited.type}
                {lastVisited &&
                  lastVisited.dischargeDate &&
                  lastVisited.type === "InPatient" &&
                  ` - DD - ${getFormateDate(
                    lastVisited.dischargeDate,
                    "discharge"
                  )}`}
              </option>
            ))}
        </select>
        {/* </div> */}
      </div>
    </>
  );
}

export default VisitList;
