import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Alert } from "@mui/material";
import BtnCellRenderer from "../common/BtnCellRenderer";
import { patientActions, doctorActions } from "../../core/actions";
import Components from "..";
import { SET_PAGE_HEADER } from "../../shared/constants";
import { format } from "date-fns";
import CustomTooltip from "../common/CustomTooltip";
import { useHistory } from "react-router-dom";

function PatientRevisit() {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [patientRevisit, setpatientRevisit] = useState();
  const [showPatientRevisitModal, setshowPatientRevisitModal] = useState(false);
  const [showPatientVisitModal, setshowPatientVisitModal] = useState(false);
  const [onBoardmodal, setOnBoardModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("OutPatient");
  const [selectPatientData, setselectPatientData] = useState();
  const [selectedPatiemtSummary, setSummary] = useState();
  const dispatch = useDispatch();
  const [doctorList, setdoctorList] = useState({
    doctors: "",
  });
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [patientForm, setPatientForm] = useState(false);
  const todayDate = format(new Date(), "yyyy-MM-dd");

  // Get the current time and format it as "HH:mm" for the time input field
  const currentTime = format(new Date(), "HH:mm");

  // Set the default values
  const [selecteddate, setSelecteddate] = useState(todayDate);
  const [selectedTime, setSelectedTime] = useState(currentTime);
  const [appoinment, setAppointment] =useState(false);
  const [rowData] = useState([
    {
      pName: "Pat1",
      status: "In",
      rTime: "2:00",
      aTime: "",
      counselor: "Doc1",
    },
    {
      pName: "manu",
      status: "Out",
      rTime: "2:00",
      aTime: "",
      counselor: "Doc2",
    },
    {
      pName: "upadhya",
      status: "Out",
      rTime: "3:30",
      aTime: "",
      counselor: "Doc3",
    },
  ]);

  const [columnDefs] = useState([
    // {
    //   headerName: "File Number",
    //   field: "fileNo",
    //   sortable: true,
    //   filter: true,
    //   flex: 1,
    //   resizable: true,
    //   floatingFilter: true,
    // },
    {
      headerName: "MAIN",
      field: "maIn",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Phone Number",
      field: "phno",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Scheduled Time",
      field: "scheduleddate",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    // { headerName:"Appointment Time",field: "starttime", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true },
    {
      headerName: "Comment",
      field: "comment",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      tooltipField: "comment",
    },
    {
      headerName: "Actions",
      field: "Actions",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      width: 150,
      cellRenderer: "BtnCellRenderer",
    },
  ]);

  const patientRevisitClick = () => {
    // setshowPatientVisitModal(true);
    setOnBoardModal(true);
  };

  const patientRescheduleClick = () => {
    setshowPatientRevisitModal(true);
  };
  const closeModal = (event) => {
    setshowPatientRevisitModal(false);
    setshowPatientVisitModal(false);
    console.log("event is:", event);
    if (event) {
      var ServerResVal = event;
      if (event.successMessages) {
        ServerResVal.success = event.successMessages[0];
      } else {
        ServerResVal.error = event.errorMessages[0];
      }
      setServerRes({ ...ServerResVal });
      setTimeout(() => {
        closeAlert();
      }, 3000);
    }
    getListRevisits();
  };

  const handleRendererCall = (props) => {
    console.log("props", props, props.revisitsList);
    setselectPatientData(props.revisitsList);
    if (props.revisitsList.typeFlag == "bookvisit") {
      patientRevisitClick(props.revisitsList);
    } else if (props.revisitsList.typeFlag == "appointment") {
      console.log("props.revisitsList", props.revisitsList);
      // setAppointment(true);
      history.push({
        pathname: `/Appointments/${props.revisitsList.id}`,
      });
    } else {
      patientRescheduleClick(props.revisitsList);
    }
  };

  var d1 = new Date();
  var d2 = new Date();
  const date1 = format(d1.setDate(d1.getDate() - 3), "yyyy/MM/dd");
  const date2 = format(d2.setDate(d2.getDate() + 30), "yyyy/MM/dd");
  console.log("date1", date1);
  console.log("date2", date2);

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    return formatteddate;
  };

  const getFormattedScheduledDate = (date) => {
    const scheduleddate = new Date(date);
    let formatedscheduleddate = format(scheduleddate, "MMM dd yyyy");
    return formatedscheduleddate;
  };

  const toggle = (event) => {
    setOnBoardModal(false);
  };

  const onValueChange = (value) => {
    setSelectedRadio(value);
    console.log("selectedOptionRadio", value);
  };

  const onboardPatient = () => {
    var ServerResVal = ServerRes;
    console.log("log in onboard method:", selectedRadio);
    //visitList['type'] = data;
    let type = selectedRadio;

    let admissionDateTime = "";
    if (type == "InPatient") {
      const dateTimeString = `${selecteddate} ${selectedTime}:00`;
      admissionDateTime = dateTimeString;
      console.log("timestamp", admissionDateTime);
    }

    console.log("type in log:", type);
    let visitListDetails = { ...selectPatientData };
    console.log("visitlist details in onboard", visitListDetails.registeredOn);
    if (visitListDetails.registeredOn) {
      let originalDate = new Date(visitListDetails.registeredOn);
      let sixMonthsLater = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth(),
        originalDate.getDate() - 180,
        originalDate.getHours(),
        originalDate.getMinutes(),
        originalDate.getSeconds(),
        originalDate.getMilliseconds()
      );
      console.log("formatted date is", sixMonthsLater);
    }
    visitListDetails.onboardStatus = "reVisitPatient";
    // console.log("log in onboard method:", value);
    dispatch(
      patientActions.onboardPatient(
        visitListDetails,
        type,
        admissionDateTime,
        (resCb) => {
          if (resCb) {
            console.log("onboardPatient response", resCb);
            if (resCb.successMessages) {
              let visitId = resCb.patientresp.visit.id;
              let patientId = resCb.patientresp.id;
              let name = resCb.patientresp.fname;
              let erpCustId = resCb.patientresp.erpCustId;
              let visitStatus = resCb.patientresp.visit.status;
              ServerResVal.success = "Patient onboarded successfully";
              setServerRes({ ...ServerResVal });
              setTimeout(() => {
                closeAlert();
              }, 3000);
              setTimeout(() => {
                if (visitStatus && visitStatus == "open") {
                  history.push({
                    pathname: `/PatientSections/${patientId}/${visitId}`,
                  });
                } else {
                  history.push({
                    pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
                  });
                }
              }, 2000);
            } else if (resCb.state === "error") {
              ServerResVal.error = "Patient onboard failed";
              setServerRes({ ...ServerResVal });
            }

            setOnBoardModal(false);
          }
        }
      )
    );
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };

  // const getFormattedApptDate =(date)=>{
  //     const apptdate =new Date(date);
  //     let formatedapptdate= format(apptdate, "MMM dd yyyy");
  //   return formatedapptdate;
  // }

  const revisitsList = useSelector(
    (state) => state.patientDetailsState.revisitsList
  );
  console.log("visitsList", revisitsList);

  useEffect(() => {
    // let data ={}
    // let date1 ={}
    // let date2 ={}
    sessionStorage.removeItem("searchvalue");
    getListRevisits();
  }, []);

  const handleInputChange = (event) => {
    console.log("You can search..!");
    console.log("Event is", event.target.value);
    if (event.target.value.length > 2) {
      getSearchedFollowUps(event.target.value);
    } else {
      getListRevisits();
    }
  };

  const getListRevisits = () => {
    let searchFollowUps = "";
    dispatch(
      patientActions.ListRevisits(date1, date2, searchFollowUps, (resCb) => {
        if (resCb) {
          console.log("All Bed Details - Response", resCb);
          dispatch(patientActions.setPageHeader("Followup"));
          console.log("visitsList1", resCb);
          console.log("Object", Object.values(resCb));
          let patientRevisit = resCb.patient;
          console.log("patientVisit:", patientRevisit);
          for (let item of patientRevisit) {
            console.log("revisit object is", item);
            item["fileNo"] = item.fileNo;
            item["maIn"] = item.id;
            item["name"] = item.fname + " " + item.mname + " " + item.lname;
            item["phno"] = item.phno;
            item["status"] = item.revisit.status;
            // item['scheduledTime'] = getFormattedDate(item.revisit.scheduledTime);
            item["scheduleddate"] = item.revisit.scheduleddate;
            item["comment"] = item.revisit.comment;
          }

          console.log("Object", patientRevisit);
          setpatientRevisit(patientRevisit);
        }
      })
    );
  };

  const getSearchedFollowUps = (searchFollowUps) => {
    //  let searchFollowUps = 'abh';
    dispatch(
      patientActions.ListRevisits(date1, date2, searchFollowUps, (resCb) => {
        if (resCb) {
          console.log("All Bed Details - Response", resCb);
          dispatch(patientActions.setPageHeader("Followup"));
          console.log("visitsList1", resCb);
          console.log("Object", Object.values(resCb));
          let patientRevisit = resCb.patient;
          console.log("patientVisit:", patientRevisit);
          for (let item of patientRevisit) {
            console.log("item", item);
            item["fileNo"] = item.fileNo;
            item["maIn"] = item.id;
            item["name"] = item.fname + " " + item.mname + " " + item.lname;
            item["phno"] = item.phno;
            // item['status']=item.status;
            // item['scheduledTime'] = getFormattedDate(item.revisit.scheduledTime);
            item["scheduleddate"] = item.revisit.scheduleddate;
            item["comment"] = item.comment;
          }

          console.log("Object", patientRevisit);
          setpatientRevisit(patientRevisit);
        }
      })
    );
  };

  const openPatientForm = (event) => {
    setPatientForm(true);
  };

  const closePatientFormModal = () => {
    setPatientForm(false);
  };

  const updatePatientDetails = (event, payment) => {
    var ServerResVal = ServerRes;
    if (event.successMessages) {
      ServerResVal.success = "Patient saved successfully";
    } else {
      ServerResVal.error = "Failed to save Patient";
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 3000);
  };

  const context = { componentParent: (props) => handleRendererCall(props) };
  console.log("patientRevisit", revisitsList);
  console.log("revisitsList1", revisitsList.type);
  const rowHeight = 100;

  const handleOnChange = (value, event) => {
    if (value === "date") {
      setSelecteddate(event.target.value);
      console.log("Seleected Date is", event.target.value, event);
      console.log("Seleected Date is", typeof selecteddate);
    }
    if (value === "time") {
      setSelectedTime(event.target.value);
      console.log("Seleected Date is", event.target.value, event);
      console.log("Seleected Date is", typeof selectedTime);
    }
  };

  return (
    <>
      <div className="m-2">
        {ServerRes.success && (
          <Alert severity="success" className="alert-div">
            {ServerRes.success}{" "}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(event) => closeAlert(event)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Alert>
        )}
        {ServerRes.error && (
          <Alert severity="error" className="alert-div">
            {ServerRes.error}{" "}
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={(event) => closeAlert(event)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Alert>
        )}
      </div>
      {/* <Row noGutters className="page-header mb-3 mx-2"> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 3,
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        {/* <Col className='col-md-12 mt-2'> */}
        <Grid
          item
          xs={12}
          md={10}
          sx={{ mt: 2 }}
          className=" d-flex followUpSearch"
        >
          <form className="main-navbar__search w-100 d-md-flex d-lg-flex ">
            <div className="input-group input-group-seamless">
              <div className="input-group-prepend mb-2">
                <span className="input-group-text">
                  <i style={{ color: `#111b21` }} className="material-icons">
                    search
                  </i>
                </span>
              </div>
              <TextField
                className="navbar-search mb-2 menu-search bg-white custom-text"
                placeholder="Search Followup of Patients by Name/ MAIN/ PhoneNumber..."
                autoFocus
                type="search"
                onChange={handleInputChange}
                InputProps={{
                  style: { paddingLeft: "15px", height: "35px" }, // Adjust padding as needed
                }}
              />
            </div>
          </form>
          {/* <Button
            variant="contained"
            color="primary"
            type="submit"
              className=""
              onClick={(event) => openPatientForm(event)}
              style={{ textTransform: "none" }}
             >
             Appointment
           </Button> */}
        </Grid>
      </Box>
      <h5 className="page-title my-2 mx-3">Follow-up</h5>
      <div>
        <div className="preview p-3">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{
                BtnCellRenderer: BtnCellRenderer,
                CustomTooltip: CustomTooltip,
              }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              context={context}
              rowData={patientRevisit}
              columnDefs={columnDefs}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              // rowHeight={rowHeight}
            ></AgGridReact>
          </div>
          {showPatientRevisitModal ? (
            <Components.PatientRevisitModal
              patientData={selectPatientData}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}
          {showPatientVisitModal ? (
            //  <Components.GiveAppointment
            <Components.AppointmentScheduleModal
              patientData={selectPatientData}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}

          {patientForm ? (
            <Components.PatientForm
              patientInfo={[]}
              openPatientForm={true}
              appointment={true}
              type="patientRevisit"
              closePatientForm={() => closePatientFormModal()}
              onSuccess={(event) => updatePatientDetails(event, "payment")}
            />
          ) : (
            ""
          )}

          <Dialog
            id="patientRevisit"
            open={onBoardmodal}
            onClose={toggle} // Use the onClose event handler to handle modal closing
            className="d-flex w-95 h-90 flex-column-reverse dialog"
          >
            <div>
              <DialogTitle>
                Select Patient Category
                <IconButton
                  aria-label="close"
                  onClick={toggle}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent style={{ padding: "0px 24px" }}>
                <span>Are you sure to OnBoard a patient?</span>
              </DialogContent>
              <DialogActions
                className="d-flex flex-column align-items-end"
                style={{ paddingTop: "0px" }}
              >
                <RadioGroup
                  row
                  aria-label="patientCategory"
                  name="patientCategory"
                  value={selectedRadio}
                  onChange={(event) => onValueChange(event.target.value)}
                  className="d-flex w-100 justify-content-between"
                >
                  <FormControlLabel
                    value="InPatient"
                    control={<Radio />}
                    label="InPatient"
                  />
                  <FormControlLabel
                    value="OutPatient"
                    control={<Radio />}
                    label="OutPatient"
                  />
                  <FormControlLabel
                    value="OnlinePatient"
                    control={<Radio />}
                    label="OnlinePatient"
                  />
                </RadioGroup>
                {selectedRadio === "InPatient" ? (
                  <div style={{ marginRight: `30%` }}>
                    <label style={{ paddingTop: "5px", paddingBottom: `20px` }}>
                      Select a Admission Date:
                    </label>
                    <Grid container spacing={2} alignItems="center">
                      {/* Date Input */}
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          name="admissionDate"
                          id="admissionDate"
                          type="date"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={selecteddate}
                          //  defaultValue={selecteddate}
                          onChange={(event) => handleOnChange("date", event)}
                        />
                      </Grid>

                      {/* Time Input */}
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <TextField
                          name="admissionTime"
                          id="admissionTime"
                          type="time"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={selectedTime}
                          onChange={(event) => handleOnChange("time", event)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-style bottomright"
                  onClick={onboardPatient}
                  style={{ textTransform: "none", marginRight: "15px" }}
                >
                  Save
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
}
export default PatientRevisit;
