import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
// import Collapse from '@mui/material/Collapse';
import Paper from "@mui/material/Paper";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableRow from '@mui/material/TableRow';
import {
  APPT_MORNING_TIMINGS,
  APPT_EVENING_TIMINGS,
} from "../../shared/constants";
import {
  doctorActions,
  patientActions,
  tokenActions,
} from "../../core/actions";
import { helperServices } from "../../shared/helper";
import Select, { createFilter } from "react-select";
import { useHistory } from "react-router-dom";
import Components from "./index";

function AppointmentScheduleModal(props) {
  const [requestedtime, setRequestedtime] = useState({ time: "" });
  const [addNewAppmnt, setAddNewAppmnt] = useState(false);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [defaultweekDays, setDefaultweekDays] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [selecetdDate, setSelecetdDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [leaveSolts, setLeaveSolts] = useState([]);
  const [reloadModal, setReloadModal] = useState(false);
  const [timeSelected, setTimeSelected] = useState();
  var startDate = "";
  var endDate = "";
  var docCalender;
  const [onmodal, setOnModal] = useState(true);
  const [appointmentDetail, setappointmentDetail] = useState({
    reqtime: "",
    reqtdate: "",
    reqthours: "",
    reqtminutes: "",
    apptdate: "",
    appthours: "",
    apptminutes: "",
    type: "Doctor",
    counselor: "",
    patientId: "",
    visitId: "",
    vaId: "",
    v: "",
    va: "",
    gvid: "",
    calField: "",
    timeslots: [],
    c: {},
    departmentId: "",
  });
  const [visitdetail, setVisitdetail] = useState({
    patientId: "",
    reqtime: "",
    reqtdate: "",
    reqthours: "",
    reqtminutes: "",
    counselor: "",
    apptdate: "",
    appthours: "",
    apptminutes: "",
    type: "Doctor",
    vaId: "",
    status: "",
    reId: "",
    gvid: "",
    calField: "",
    patientType: "OutPatient",
    timeslots: [],
    c: {},
  });
  const [pDetails, setPDetails] = useState({
    reqtime: "",
    reqtdate: "",
    reqthours: "",
    reqtminutes: "",
    type: "Doctor",
    counselor: "",
    patientId: "",
    visitId: "",
    vaId: "",
    v: "",
    va: {},
    Patient: {},
  });
  const dispatch = useDispatch();
  const [doctorList, setdoctorList] = useState([]);
  const [flag, setFlag] = useState({});
  const [appBooked, setAppBooked] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [filteredTimeSlot, setfilteredTimeSlot] = useState({});
  const [showAppointedPatients, setShowAppointedPatients] = useState(false);
  const [flagStatus, setFlagStaus] = useState(false);

  const [doctorDetails, setDoctorDetails] = useState({
    gvid: "",
    date: "",
    appthours: "",
    apptminutes: "",
    patientId: "",
  });

  const [collapseMoringTime, setCollapseMoringTime] = useState(true);
  const [collapseEveTime, setCollapseEveTime] = useState(false);
  const [reloadData, setreloadData] = useState(false);
  const [calender, setCalender] = useState({});
  const [getCal, setGetCal] = useState({});
  const [doctorCalender, setDoctorCalender] = useState({});
  const [formErrors, setFormErrors] = useState({
    counselor: "",
    apptdate: "",
    type: "",
  });
  const [timeslotsFromDb, setTimeslotsFromDb] = useState([]);
  const [selectedDoctor, setselectedDoctor] = useState([]);
  const [bookedCountAtTime, setBookedCountAtTime] = useState({});
  const [bookedPatientSlots, setBookedPatientSlots] = useState([]);
  const [toCancelSlot, setToCancelSlot] = useState({});
  const [appointedPatient, setAppointedPatient] = useState([]);
  const [flagNotAvailable, setFalgNotAvailble] = useState(false);
  const currentTime = format(new Date(), "HH:mm");
  const [fromTime, setFromTime] = useState("8:00");
  const [toTime, setToTime] = useState("20:45");
  const [selectedDateForValidation, setSelectedDateForValidation] = useState(
    {}
  );

  const [selectedRadio, setSelectedRadio] = useState("OutPatient");
  const [selectedDay, setSelectedDay] = useState("FullDay");
  const [timesoltList, setTimesoltList] = useState([]);

  useEffect(() => {
    console.log("-------APPOINTMENT MODAL-------", props);
    setOnModal(true);
    getWeekDays();
    getDoctorsList();
    setSelectedDateForValidation(format(new Date(), "yyyy-MM-dd"));
    console.log("setSelectedDateForValidation", selectedDateForValidation);

    if (!props.doctorHomeView) {
      setFlag(props.patientData.typeFlag);

      if (props.patientData.visit || props.patientData.revisit) {
        scheduleDoctorAppointment();
      }
    }
  }, []);

  const scheduleDoctorAppointment = () => {
    let check = "";
    let patientVisit = props.patientData;
    if (props.patientData.typeFlag == "bookvisit") {
      // Schedule doctor appointment for followup patient
      setFlag(props.patientData.typeFlag);
      visitdetail.patientId = patientVisit.id;
      visitdetail.reqtdate = patientVisit.revisit.scheduledTime;
      visitdetail.status = "open";
      visitdetail.reId = patientVisit.revisit.id;
      visitdetail.apptdate = patientVisit.revisit.scheduleddate;
      defaultDateSelection();
      formatdatentime(patientVisit.revisit.scheduleddate);
      if (visitdetail.counselor != "") {
        getdoctorCalender(doctorCalender);
      }
    } else {
      // Schedule doctor appointment for new patient
      console.log("props.patientData", props.patientData.typeFlag);
      console.log("patientVisit", patientVisit);
      pDetails.visitId = patientVisit.visit.id;
      pDetails.v = patientVisit.visit;
      appointmentDetail.patientId = patientVisit.id;
      appointmentDetail.visitId = patientVisit.visit.id;
      appointmentDetail.v = patientVisit.visit;
      if (patientVisit.visit.reqtdate != null) {
        appointmentDetail.apptdate = patientVisit.visit.reqtdate;
        defaultDateSelection();
      } else {
        appointmentDetail.apptdate = patientVisit.visit.apptdate;
        defaultDateSelection();
      }
      for (let va of patientVisit.visitAssignmentList) {
        if (props.type == "Doctor") {
          if (
            "Doctor" === va.type &&
            va.targetid != 0 &&
            va.status === "open"
          ) {
            pDetails.vaId = va.id;
            pDetails.va = va;
            appointmentDetail.va = va;
            appointmentDetail.vaId = va.id;
            appointmentDetail.counselor = va.targetid;
            appointmentDetail.gvid = va.targetid;
            check = false;
            break;
          }
        } else if (props.type == "Counsellor") {
          if (
            "Counsellor" === va.type &&
            va.targetid != 0 &&
            va.status === "open"
          ) {
            pDetails.vaId = va.id;
            pDetails.va = va;
            appointmentDetail.va = va;
            appointmentDetail.vaId = va.id;
            appointmentDetail.counselor = va.targetid;
            appointmentDetail.gvid = va.targetid;
            check = false;
            break;
          }
        }
      }
      if (check != "false") {
        appointmentDetail.va = patientVisit.visitAssignment;
      }
      formatdatentime(patientVisit.visit.reqtdate);
      if (appointmentDetail.counselor != "") {
        getdoctorCalender(doctorCalender);
      }
      // if (props.doctorInfo.gvId === "" && props.doctorInfo.gvId === undefined ) {
      //   console.log("api is not called");
      // } else {

      //   getCal.apptdate = format(new Date(), "yyyy-MM-dd");
      //   getCal.gvid = props.doctorInfo.gvId; // Change this line to getCal.gvid
      //   getCalDoc(getCal);
      // }
      if (props && props.doctorInfo) {
        if (props.doctorInfo.gvId === "") {
          console.log("Doctor API is not called");
        } else {
          getCal.apptdate = format(new Date(), "yyyy-MM-dd");
          getCal.gvid = props.doctorInfo.gvId;
          getCalDoc(getCal);
        }
      } else if (props && props.CounsellorInfo) {
        if (props.CounsellorInfo.gvId === "") {
          console.log("Counsellor API is not called");
        } else {
          getCal.apptdate = format(new Date(), "yyyy-MM-dd");
          getCal.gvid = props.CounsellorInfo.gvId;
          getCalDoc(getCal);
        }
      }
    }
  };

  const getDoctorsList = (data) => {
    let gvdata;
    dispatch(
      doctorActions.getDoctorList(data, (resCb) => {
        if (resCb) {
          let list = [];
          console.log("rescb from getdoctorlist: ", resCb);
          for (let doc of resCb) {
            console.log("doc: ", doc);
            if (doc != null) {
              let opt = {};
              if (doc && !doc.first_name) {
                doc.first_name = "";
              }
              if (doc && !doc.last_name) {
                doc.last_name = "";
              }
              if (doc && doc.gvId) {
                opt["id"] = doc.personId;
                opt["gvid"] = doc.gvId;
              }
              //opt["id"] = doc.gvId;

              const name = helperServices.getFullName(
                doc.first_name,
                null,
                doc.last_name
              );
              opt["role"] = doc.role;
              opt["type"] = doc.type;
              opt["value"] = doc.email;
              opt["department"] = doc.department;
              opt["firstName"] = doc.first_name;
              opt["lastName"] = doc.last_name;
              opt["departmentId"] = doc.departmentId;
              opt["label"] =
                doc.displaytitle +
                "." +
                " " +
                name +
                ", " +
                "Dep of " +
                doc.department +
                " (" +
                doc.qualification +
                " )";
              list.push(opt);
              gvdata = props.doctorInfo || props.selectedCousellerInfo;
              console.log("props.doctorinfo: ", props);
              console.log("gvdata and doc: ", gvdata, doc);
              //TC_02
              let gvid;
              if (gvdata && gvdata.id) {
                gvid = gvdata.id;
              } else if (gvdata && gvdata.gvId) {
                gvid = gvdata.gvId;
              } else {
                gvid = "";
              }
              if (gvdata && gvid == doc.personId) {
                setselectedDoctor(opt["label"]);
                console.log("selected doctor: ".selectedDoctor);
                let app = appointmentDetail;
                // app.counselor = gvdata.gvId;
                app.counselor = doc.gvId;
                app.departmentId = doc.departmentId;
                console.log("app:", app);
                setappointmentDetail({ ...app });
              }
            }
          }

          console.log("list of doctors:", list);
          list = list.filter(
            (item) =>
              item && item.type.toLowerCase() == props.type.toLowerCase()
          );
          console.log("list of doctors after filter:", list);

          setdoctorList([...list]);
        }
      })
    );
  };

  const getdoctorCalender = () => {
    if (flag != "bookvisit") {
      // getCal.apptdate = appointmentDetail.apptdate;
      getCal.apptdate =
        appointmentDetail.apptdate == format(new Date(), "yyyy-MM-dd")
          ? format(new Date(), "yyyy-MM-dd")
          : appointmentDetail.apptdate;

      getCal.gvid = appointmentDetail.counselor;
    } else {
      // getCal.apptdate = visitdetail.apptdate;
      getCal.apptdate =
        visitdetail.apptdate == format(new Date(), "yyyy-MM-dd")
          ? format(new Date(), "yyyy-MM-dd")
          : visitdetail.apptdate;

      getCal.gvid = visitdetail.counselor;
    }
    if (!getCal.apptdate || !getCal.gvid) return;
    dispatch(
      patientActions.getDoctorCalenderByDate(getCal, (resCb) => {
        if (resCb) {
          console.log("foreacjhhfkvkfbkhvbkjrekuf", resCb);
          const filteredTimeSlot = { ...resCb.c };
          const keysToRemove = ["id", "gvid", "date"];
          keysToRemove.forEach((key) => delete filteredTimeSlot[key]);
          console.log("filteredTimeSlot", filteredTimeSlot);
          setfilteredTimeSlot(filteredTimeSlot);
          let timeslotsFromDb = [];
          if (resCb.c != null) {
            Object.keys(resCb.c).forEach((key) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id"
              ) {
                timeslotsFromDb.push(key);
              }
            });
          }
          console.log("timeslotsFromDb", timeslotsFromDb);
          let bookedCountAtTimeloc = {};
          if (resCb.c != null) {
            Object.entries(resCb.c).map(([key, value]) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                bookedCountAtTimeloc[key] = value.split(",").length;
                bookedCountAtTimeloc["pateintId"] = value.split(",");
              }
            });
          }
          setBookedCountAtTime(bookedCountAtTimeloc);

          let bookedPatientSlot = [];
          let patientId = appointmentDetail.patientId;
          if (resCb.c != null) {
            Object.entries(resCb.c).forEach(([key, value]) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                if (
                  resCb.c[key].includes(patientId) != resCb.c[key].includes(key)
                ) {
                  bookedPatientSlot.push(key);
                  console.log("leaveSolts", leaveSolts);
                }
              }
            });
          }
          if (flag != "bookvisit") {
            setBookedPatientSlots(bookedPatientSlot);
          }
          setDoctorCalender(timeslotsFromDb);
          setTimeslotsFromDb(timeslotsFromDb);
        }
      })
    );
  };

  const getCalDoc = (getCal) => {
    dispatch(
      patientActions.getDoctorCalenderByDate(getCal, (resCb) => {
        if (resCb) {
          console.log("foreacjhhfkvkfbkhvbkjrekuf", resCb);
          const filteredTimeSlot = { ...resCb.c };
          const keysToRemove = ["id", "gvid", "date"];
          keysToRemove.forEach((key) => delete filteredTimeSlot[key]);
          console.log("filteredTimeSlot", filteredTimeSlot);
          setfilteredTimeSlot(filteredTimeSlot);
          let timeslotsFromDb = [];
          if (resCb.c != null) {
            Object.keys(resCb.c).forEach((key) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id"
              ) {
                timeslotsFromDb.push(key);
              }
            });
          }
          console.log("timeslotsFromDb", timeslotsFromDb);
          let bookedCountAtTimeloc = {};
          if (resCb.c != null) {
            Object.entries(resCb.c).map(([key, value]) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                bookedCountAtTimeloc[key] = value.split(",").length;
                bookedCountAtTimeloc["pateintId"] = value.split(",");
              }
            });
          }
          setBookedCountAtTime(bookedCountAtTimeloc);

          let bookedPatientSlot = [];
          let patientId = appointmentDetail.patientId;
          if (resCb.c != null) {
            Object.entries(resCb.c).forEach(([key, value]) => {
              if (
                resCb.c[key] != null &&
                resCb.c[key] != "" &&
                key != "gvid" &&
                key != "id" &&
                key != "date"
              ) {
                if (
                  resCb.c[key].includes(patientId) != resCb.c[key].includes(key)
                ) {
                  bookedPatientSlot.push(key);
                }
              }
            });
          }
          if (flag != "bookvisit") {
            setBookedPatientSlots(bookedPatientSlot);
          }
          setDoctorCalender(timeslotsFromDb);
          setTimeslotsFromDb(timeslotsFromDb);
        }
        setTimeout(() => {
          console.log("Code executed after 2 seconds");
        }, 5000);
      })
    );
  };
  const formatdatentime = (date) => {
    if (!date) return;
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    setRequestedtime({
      time: formatteddate,
    });
    return formatteddate;
  };

  const closeModal = () => {
    props.onModalClose();
  };

  const addNewAppointment = (time, type) => {
    const time1 = new Date();
    time1.setHours(time.split(":")[0]);
    time1.setMinutes(time.split(":")[1]);
    console.log("bookedPatientSlotshgghgggjyj", time1, time, type);
    // setTimeSelected(time1);
    // if (leaveSolts) {
    //   Object.entries(leaveSolts).forEach(([key, value]) => {
    //     if (value == type) {
    //       let fieldValidationErrors = formErrors;
    //       fieldValidationErrors.type =
    //       "Doctor is not available on selected timeslot";
    //     setFormErrors(fieldValidationErrors);
    //     }
    //   });
    // } else
    {
      let validation = validateField();
      console.log("validation", validation);
      if (validation == true) {
        if (props.doctorHomeView) {
          if (filteredTimeSlot.hasOwnProperty(type)) {
            var patientIds = filteredTimeSlot[type];
            dispatch(
              patientActions.getAppointedPatientDetails(patientIds, (resCb) => {
                if (resCb) {
                  //console.log("resCb.patient",appointedPatientList);
                  //var appointedPatientList = [];
                  //appointedPatientList.push(resCb.patient);
                  setAppointedPatient(resCb.patient);
                  setShowAppointedPatients(true);
                }
              })
            );
            console.log("filteredTimeSlot", patientIds);
          }
        }
      }
      setreloadData(!reloadData);
      let apptdateResult = validateFieldForApptDate(time1, type);
      if (!apptdateResult) {
        return;
      }
      let result = validateFieldTimeSlots();
      if (!result) {
        return;
      }
      if (timeSlots.valueOf(0) == "") {
        appointmentDetail.appthours = time.split(":")[0];
        appointmentDetail.apptminutes = time.split(":")[1];
        visitdetail.appthours = time.split(":")[0];
        visitdetail.apptminutes = time.split(":")[1];
      }
      docCalender = calender;

      if (timeSlots.indexOf(type) > -1) {
        docCalender[type] = "";
        setCalender(docCalender);
        timeSlots.splice(timeSlots.indexOf(type), 1);
      } else {
        timeSlots.push(type);
        docCalender[type] = props.patientData.id;
        setCalender(docCalender);
      }
      setreloadData(!reloadData);
      setTimeSlots(timeSlots);

      appointmentDetail.timeslots = timeSlots;
      appointmentDetail.c = calender;

      visitdetail.timeslots = timeSlots;
      visitdetail.c = calender;

      if (flag != "bookvisit") {
        setappointmentDetail(appointmentDetail);
      } else {
        setVisitdetail(visitdetail);
      }
      setAppBooked(true);
    }
  };

  const tokenChange = (visitId, gvId, type) => {
    // console.log("event in tokenchange method", event);
    if (
      props &&
      props.patientData &&
      props.patientData.visit &&
      props.patientData.visit.type == "OutPatient" &&
      props.patientData.visit.status == "open" &&
      props.type == "Doctor"
    ) {
      if (
        props &&
        props.patientData &&
        props.patientData.tokenNumber &&
        parseInt(props.patientData.tokenNumber) > 0
      ) {
        dispatch(
          tokenActions.recorrectToken(visitId, gvId, (resCb) => {
            if (resCb) {
              console.log("token responses", resCb.token);
            }
          })
        );
      } else {
        dispatch(
          tokenActions.tokenGenerate(visitId, gvId, (resCb) => {
            if (resCb) {
              console.log("token responses", resCb.token);
            }
          })
        );
      }
    }
  };

  const submit = () => {
    validateField();
    var ServerResVal = ServerRes;
    console.log("selected docator", selectedDoctor);
    console.log("Visit Details", visitdetail, appointmentDetail);
    let validation = validateField();
    console.log("validation", validation);
    if (validation == true) {
      if (flagStatus == false) {
        let currentDate = new Date();
        let curDate = format(currentDate, "yyyy-MM-dd");
        console.log("currentdate:", curDate);
        setSelecetdDate(curDate);
        appointmentDetail.apptdate = curDate;
        visitdetail.apptdate = curDate;
        setappointmentDetail(appointmentDetail);
        getdoctorCalender(doctorCalender);
        // let apptdate = curDate.toString();
        // setSelectedDateForValidation(apptdate);
      }
      validateFieldForApptDate(true);
      validateFieldTimeSlots(true);
      if (props.patientData.typeFlag == "bookvisit") {
        console.log("visitdetail in revisit api", visitdetail);
        dispatch(
          patientActions.reAddVisit(visitdetail, (resCb) => {
            if (resCb) {
              setStatus({ type: "success" });
              props.onModalClose(resCb);
            }
          })
        );
      } else {
        let type = props.type;
        console.log("Appointment details is", appointmentDetail);
        if (
          appointmentDetail.apptdate == null ||
          appointmentDetail.apptdate == undefined
        ) {
          appointmentDetail.apptdate = new Date();
          console.log("AppointmentDetails", appointmentDetail.apptdate);
        }
        dispatch(
          patientActions.addAppointment(appointmentDetail, type, (resCb) => {
            if (resCb) {
              setStatus({ type: "success" });
              ServerResVal.success = "Appointment Scheduled Successfully.";
              resCb["patientresp"] = props.patientData;
              props.onModalClose(resCb);
              tokenChange(
                appointmentDetail.visitId,
                appointmentDetail.gvid,
                appointmentDetail.type
              );
            }
          })
        );
      }
    }
  };

  const cancel = (data) => {
    toCancelSlot["patientId"] = appointmentDetail.patientId;
    toCancelSlot["gvid"] = appointmentDetail.gvid;

    if (appointmentDetail.apptdate == undefined) {
      toCancelSlot["date"] = appointmentDetail.v.capptdate;
      console.log("capptdate", appointmentDetail.v.capptdate);
    } else {
      toCancelSlot["date"] = appointmentDetail.apptdate;
    }
    //toCancelSlot["date"] = appointmentDetail.apptdate;
    toCancelSlot["vaid"] = appointmentDetail.vaId;
    toCancelSlot["type"] = props.type;
    console.log("toCancelSlot", toCancelSlot);
    var ServerResVal = ServerRes;
    dispatch(
      patientActions.cancelDoctorCalenderByPatientId(toCancelSlot, (resCb) => {
        if (resCb) {
          setStatus({ type: "success" });
          ServerResVal.success = "Appointment Cancelled Successfully.";
          resCb["patientresp"] = props.patientData;
          props.onModalClose(resCb);
          getdoctorCalender(doctorCalender);
          setFlagStaus(false);
        }
      })
    );
  };

  const getWeekDays = () => {
    let curr = new Date();
    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      console.log("first:", first, day);
      let curDate = format(day, "yyyy-MM-dd");
      defaultweekDays.push(curDate);
      weekDays.push(day);
      let formatteddate = format(day, "dd MMM");
      currentWeek.push(formatteddate);
    }

    startDate = currentWeek[0];
    endDate = currentWeek[currentWeek.length - 1];
    setReloadModal(!reloadModal);
  };

  const validateFieldTimeSlots = () => {
    let currentDate = new Date();
    let curDate = format(currentDate, "yyyy-MM-dd");
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.type = "";
    if (
      bookedPatientSlots != "" &&
      selectedDateForValidation != " " &&
      Object.keys(selectedDateForValidation).length !== 0 &&
      selectedDateForValidation >= curDate
    ) {
      fieldValidationErrors.type =
        "This doctor already has appointment on same date, Click cancel appointment to cancel it first.";
      setFormErrors(fieldValidationErrors);
      return false;
    }
    return true;
  };

  const validateFieldForApptDate = (selectedTimeslot, type) => {
    let currentDate = new Date();
    let curDate = format(currentDate, "yyyy-MM-dd");
    let currentTime = new Date();
    currentTime.setHours(currentDate.getHours());
    currentTime.setMinutes(currentDate.getMinutes());
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.apptdate = "";
    console.log("appointment selecrted", selectedDateForValidation, curDate);
    console.log("timeSelectedtime", currentTime, timeSelected);
    let isDoctorUnavailable = Object.entries(leaveSolts).some(
      ([key, value]) => value === type
    );
    if (isDoctorUnavailable) {
      fieldValidationErrors.type =
        "Doctor is not available during the selected time";
      setFormErrors(fieldValidationErrors);
      return false;
    }
    if (selectedDateForValidation < curDate) {
      fieldValidationErrors.type =
        "You can not book an appointment for earlier date";
      setFormErrors(fieldValidationErrors);
      return false;
    } else if (selectedDateForValidation == curDate) {
      if (selectedTimeslot < currentTime) {
        console.log("timeSelectedtime", currentTime, timeSelected);
        fieldValidationErrors.type =
          "You can not book an appointment for earlier timeslot";
        setFormErrors(fieldValidationErrors);
        return false;
      }
      if (selectedDateForValidation == curDate) {
        //TC_01
        if (bookedPatientSlots != "") {
          console.log("timeSelectedtime", currentTime, timeSelected);
          fieldValidationErrors.type =
            "Patient already has appointment on different time slot. You may cancel it before assign new timeslot";
          setFormErrors(fieldValidationErrors);
          return false;
        }
      }
    }
    setFormErrors(fieldValidationErrors);
    return true;
  };

  const validateField = () => {
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.counselor = "";
    if (flag != undefined || flag != "bookvisit") {
      if (appointmentDetail.counselor == "") {
        fieldValidationErrors.counselor = "Please Select Doctor";
        setFormErrors({ ...fieldValidationErrors });
        return false;
      }
      return true;
    } else {
      if (visitdetail.counselor == "") {
        fieldValidationErrors.counselor = "Please Select Doctor";
        setFormErrors(fieldValidationErrors);
        return false;
      }
      return true;
    }
  };

  const handleSelect = (event, date) => {
    //TC_04
    setLeaveSolts([]);
    setTimesoltList([]);
    setFlagStaus(true);
    console.log("Selected date", date, event);
    let gvid = props?.doctorInfo?.id ?? appointmentDetail.gvid;
    let appdate = new Date(date);

    setreloadData(!reloadData);
    getleaveCalender(gvid, format(appdate, "yyyy-MM-dd"));
    validateField();
    validateFieldForApptDate(true);
    validateFieldTimeSlots(true);
    let counselorResult = validateField();
    if (!counselorResult) {
      return;
    }

    setSelecetdDate(date);
    appointmentDetail.apptdate = format(appdate, "yyyy-MM-dd");
    visitdetail.apptdate = format(appdate, "yyyy-MM-dd");
    setappointmentDetail(appointmentDetail);
    getdoctorCalender(doctorCalender);
    let apptdate = format(appdate, "yyyy-MM-dd");
    setSelectedDateForValidation(apptdate);
  };

  const nextSevenDays = () => {
    let formattedDays = [];
    let nonFormattedDays = [];
    const next7Days = [...Array(7).keys()].map((index) => {
      const date = new Date(weekDays[weekDays.length - 1]);
      date.setDate(date.getDate() + (index + 1));
      nonFormattedDays.push(date);
      let formatteddate = format(date, "dd MMM");
      formattedDays.push(formatteddate);
      return date;
    });

    setWeekDays(nonFormattedDays);
    setCurrentWeek(formattedDays);
  };

  const prevSevenDays = () => {
    let formattedDays = [];
    let nonFormattedDays = [];
    const past7Days = [...Array(7).keys()].map((index) => {
      const date = new Date(weekDays[0]);
      date.setDate(date.getDate() - (index + 1));
      nonFormattedDays.unshift(date);
      let formatteddate = format(date, "dd MMM");
      formattedDays.unshift(formatteddate);
      return date;
    });
    console.log("past7Days", past7Days);
    setWeekDays(nonFormattedDays);
    setCurrentWeek(formattedDays);
  };

  const defaultDateSelection = () => {
    let formattedDays = [];
    let nonFormattedDays = [];
    let appdate;
    let currdate = new Date();
    console.log("visitdetail1", visitdetail.apptdate);
    const curdate = format(currdate, "yyyy-MM-dd");
    console.log("curdate", curdate);
    if (props.patientData.typeFlag != "bookvisit") {
      console.log("Flag Value", props.patientData.typeFlag);
      // appdate = appointmentDetail.apptdate != curdate ? curdate : appointmentDetail.apptdate;
      appdate = curdate;
      console.log("appointmentDetail", appdate);
    } else {
      // appdate = visitdetail.apptdate;
      // appdate = visitdetail.apptdate != curdate ? curdate : appointmentDetail.apptdate;
      appdate = curdate;
      console.log("visitdetail", appdate);
    }

    if (appdate != null) {
      if (defaultweekDays.includes(appdate)) {
        console.log("defaultDateSelection weekDays", defaultweekDays);
        console.log("defaultDateSelection appdate", appdate);
        setWeekDays(defaultweekDays);
        setCurrentWeek(currentWeek);
        setSelecetdDate(appdate);
      } else if (appdate > curdate) {
        console.log("defaultDateSelection appdate1", appdate);
        const next7Days = [...Array(7).keys()].map((index) => {
          const date = new Date(weekDays[weekDays.length - 1]);
          date.setDate(date.getDate() + (index + 1));
          const defDate = format(date, "yyyy-MM-dd");
          nonFormattedDays.push(defDate);
          let formatteddate = format(date, "dd MMM");
          formattedDays.push(formatteddate);
          return date;
        });
        console.log("next7Days", next7Days);
        setWeekDays(nonFormattedDays);
        setCurrentWeek(formattedDays);
        setSelecetdDate(appdate);
      } else {
        console.log("defaultDateSelection appdate2", appdate);
        const past7Days = [...Array(7).keys()].map((index) => {
          const date = new Date(weekDays[0]);
          date.setDate(date.getDate() - (index + 1));
          const defDate = format(date, "yyyy-MM-dd");
          nonFormattedDays.unshift(defDate);
          let formatteddate = format(date, "dd MMM");
          formattedDays.unshift(formatteddate);
          return date;
        });
        console.log("past7Days", past7Days);
        setWeekDays(nonFormattedDays);
        setCurrentWeek(formattedDays);
        setSelecetdDate(appdate);
      }
    }
  };

  const onAppointmentFormSubmit = () => {
    var pDetail = appointmentDetail;
    setappointmentDetail({ ...pDetail });
    setAddNewAppmnt(false);
    document.getElementById("appointment-body").style.display = "block";

    console.log("patientData:", props.patientData);
  };

  const onInputChange = (name, value) => {
    if (name == "title") {
      appointmentDetail.title = value;
    } else {
      appointmentDetail.description = value;
    }
    setappointmentDetail(appointmentDetail);
    console.log("appointmentDetail.title", appointmentDetail.title);
    console.log("appointmentDetail.desc", appointmentDetail);
  };

  const onAppointmentFormCancel = () => {
    setAddNewAppmnt(false);
    document.getElementById("appointment-body").style.display = "block";
  };

  const handleDeptChange = (event) => {
    console.log("event:", event);
    setselectedDoctor(event.label);
    appointmentDetail.gvid = event.gvid;
    appointmentDetail.departmentId = event.departmentId;
    if (props.patientData.typeFlag == "bookvisit") {
      var docState = visitdetail;
      if (event != null) {
        docState["counselor"] = event.id;
        visitdetail.counselor = event.id;
        visitdetail.gvid = event.id;
        setselectedDoctor(event.label);
        getdoctorCalender(doctorCalender);
      }
      console.log("D-appointmentdetails", visitdetail);
    } else {
      var docState = appointmentDetail;
      if (event != null) {
        docState["counselor"] = event.id;
        appointmentDetail.counselor = event.id;
      }
      console.log("D-appointmentdetails", appointmentDetail);
      getleaveCalender(event.id, format(new Date(), "yyyy-MM-dd"));
      getdoctorCalender(doctorCalender);
      if (appointmentDetail.counselor === "") {
      } else {
        getCal.apptdate = format(new Date(), "yyyy-MM-dd");
        getCal.gvid = appointmentDetail.counselor; // Change this line to getCal.gvid
        getCalDoc(getCal);
      }
    }
    validateField();
  };

  // function getFormattedDate(date){
  //   const reqdate = new Date(date);
  //   let formatteddate = format(reqdate, "dd MMM");
  //   return formatteddate;
  // };

  const toggleMoringTime = () => {
    setCollapseMoringTime(!collapseMoringTime);
    if (!collapseMoringTime && collapseEveTime) {
      setCollapseEveTime(false);
    }
  };

  const toggleEveTime = () => {
    setCollapseEveTime(!collapseEveTime);
    if (!collapseEveTime && collapseMoringTime) {
      setCollapseMoringTime(false);
    }
  };

  const PateintType = (value) => {
    console.log("selectedOptionRadio", value);
    setSelectedRadio(value);
    visitdetail["patientType"] = value;
  };

  const handleMarkNotAvailable = () => {
    setFalgNotAvailble(true);
    setFormErrors([]);
    let list=[];
    APPT_MORNING_TIMINGS.forEach((element) => {
      list.push(element);
    });
    APPT_EVENING_TIMINGS.forEach((element) => {
      list.push(element);
    });
    setTimesoltList(list);
    setSelectedDay("FullDay");
    console.log("Processing element:", timesoltList);
  };

  const handleMarkAvailable = () => {
    setLeaveSolts([]);
    setFormErrors([]);
    let gvid = props.doctorInfo.id;
    let date = format(selecetdDate, "yyyy-MM-dd");
    dispatch(
      patientActions.updateleaveCalender(gvid, date, (resCb) => {
        if (resCb.successMessages) {
          console.log("resCb", resCb);
          setFalgNotAvailble(false);
          getleaveCalender(gvid, date);
        }
      })
    );
  };

  const getleaveCalender = (gvid, date) => {
    dispatch(
      patientActions.getleaveCalender(gvid, date, (resCb) => {
        if (resCb.successMessages) {
          console.log("resCb", resCb);
          let check = "NA";
          let slot = [];
          if (resCb.lc != null) {
            Object.entries(resCb.lc).forEach(([key, value]) => {
              if (value === check) {
                slot.push(key);
              }
            });
            console.log("leaveSolts 1065", leaveSolts, timeSlots);
          }
          setLeaveSolts(slot);
        }
      })
    );
    console.log("leaveSolts", leaveSolts, timeSlots);
  };

  const onValueChange = (value) => {
    setSelectedDay(value);
    console.log("selectedOptionRadio", value);
    if (value === "FullDay") {
      setFromTime("8:00");
      setToTime("20:45");
    }
    if (value === "reset") {
      setFormErrors([]);
    }
    setFormErrors([]);
  };

  const handleOnChange = (value, event) => {
    if (value === "fromTime") {
      // validateLeaveTime(event)
      setFromTime(event);
      console.log("Seleected Date is", event, event);
    }
    if (value === "toTime") {
      setToTime(event);
      console.log("Seleected Date is", event, event);
    }
  };

  const toggle = (event) => {
    setFalgNotAvailble(false);
    setFormErrors([]);
  };

  const findMatchingTime = (element, timeToFind) => {
    let matchedKey = null;
    let matchedIndex = -1;
    Object.keys(element).forEach((key, index) => {
      console.log(element[key], timeToFind);
      if (key.startsWith("time") && element[key] === timeToFind) {
        matchedKey = key;
        matchedIndex = parseInt(key.replace("time", "")) - 1;
      }
    });
    return { matchedKey, matchedIndex };
  };

  const done = () => {
    setLeaveSolts([]);
    if (selectedDay == "reset") {
      handleMarkAvailable();
    } else {
      let valid = validateLeaveTime(fromTime, toTime);
      if (valid) {
        let result = [];
        let resultsrray = [];
        var ServerResVal = ServerRes;
        console.log("time: type", fromTime, toTime);
        let startTime = moment(fromTime, "HH:mm");
        let endTime = moment(toTime, "HH:mm");
        let lcalender = {};
        while (startTime <= endTime) {
          resultsrray.push(startTime.format("H:mm"));
          startTime.add(15, "minutes");
        }
        console.log("resultsrray", resultsrray);
        for (let index = 0; index < resultsrray.length; index++) {
          const time = resultsrray[index];
          let type = filterTimeSlot(time);
          if (type && type != "") {
            result.push(type);
          }
          console.log("result", result);
        }
        result.forEach((element) => {
          lcalender[element] = "NA";
        });
        let gvid = props.doctorInfo.id;
        let date = format(selecetdDate, "yyyy-MM-dd");
        console.log("calender", lcalender, gvid);
        if (lcalender) {
          dispatch(
            patientActions.leaveCalender(lcalender, gvid, date, (resCb) => {
              if (resCb.successMessages) {
                console.log("resCb", resCb);
                ServerResVal.success = "Not availability added successfully.";
                toggle();
                getleaveCalender(gvid, date);
              }
            })
          );
        }
      } else {
        let gvid = props.doctorInfo.id;
        let date = format(selecetdDate, "yyyy-MM-dd");
        getleaveCalender(gvid, date);
        return;
      }
    }
  };

  const filterTimeSlot = (time) => {
    let type;
    for (let index = 0; index < timesoltList.length; index++) {
      type = "";
      const element = timesoltList[index];
      const { matchedKey, matchedIndex } = findMatchingTime(element, time);
      if (matchedIndex > -1) {
        type = element[`type${matchedIndex + 1}`];
        break;
      }
    }
    return type;
  };

  const validateLeaveTime = (time1, time2) => {
    let fieldValidationErrors = formErrors;
    let currentTime = new Date();
    const sTime = new Date(selecetdDate);
    if (time1) {
      sTime.setHours(time1.split(":")[0]);
      sTime.setMinutes(time1.split(":")[1]);
    }
    const eTime = new Date(selecetdDate);
    if (time2) {
      eTime.setHours(time2.split(":")[0]);
      eTime.setMinutes(time2.split(":")[1]);
    }
    console.log("validateLeaveTime", sTime, eTime, currentTime);

    if (currentTime >= sTime) {
      fieldValidationErrors.type = "You can not mark leave for earlier date";
      setFormErrors(fieldValidationErrors);
      return false;
    } else if (sTime >= eTime && time1 && time2) {
      fieldValidationErrors.type = "Please select a valid time range.";
      setFormErrors(fieldValidationErrors);
      return false;
    } else if (leaveSolts.length > 0) {
      fieldValidationErrors.type = "Some slots on this day are already marked unavailable.Click reset to reset those and then mark unavailability.";
      setFormErrors(fieldValidationErrors);
      return false;
    }

    return true;
  };

  return (
    <>
      <Dialog
        className="appointment-modal "
        id="appointment-modal"
        open={onmodal}
        toggle={closeModal}
      >
        {flag == "bookvisit" ? (
          <DialogTitle className="py-2">
            Re-Book appointment
            <IconButton
              aria-label="close"
              onClick={(event) => closeModal(event)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        ) : (
          <>
            {props.doctorHomeView ? (
              <DialogTitle className="py-2">
                Calendar
                <IconButton
                  aria-label="close"
                  onClick={(event) => closeModal(event)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            ) : (
              <DialogTitle className="py-2 mt-1">
                Schedule Appointment
                <IconButton
                  aria-label="close"
                  onClick={(event) => closeModal(event)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            )}
          </>
        )}
        <div className="d-flex">
          <div style={{ flex: "0 1 80%" }}>
            <div className="px-3 py-1 modal-subsection">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div className="w-50 border-right">
                    <label className="label">{props.type}: </label>
                    <div className="pr-4 py-0 w-100">
                      {props.attachOption || props.doctorHomeView ? (
                        <span className="d-flex flex-row">
                          {selectedDoctor}
                          {selecetdDate && (
                            <Button
                              id="appointment-modal1"
                              variant="contained"
                              sx={{ textTransform: "capitalize" }}
                              onClick={handleMarkNotAvailable}
                            >
                              Mark Available
                            </Button>
                          )}
                        </span>
                      ) : (
                        <Select
                          {...doctorList}
                          className="select-tag"
                          classNamePrefix="select-options"
                          name="Search Doctor"
                          value={doctorList.filter(
                            (option) => option.label === selectedDoctor
                          )}
                          onChange={handleDeptChange}
                          isClearable
                          isSearchable
                          options={doctorList}
                          filterOption={createFilter(doctorList)}
                          defaultValue={selectedDoctor}
                        ></Select>
                      )}
                    </div>
                    {!props.doctorHomeView && (
                      <div style={{ color: "#EC4147" }}>
                        <span className="err-msg">{formErrors.counselor}</span>
                      </div>
                    )}
                  </div>
                  {props.doctorHomeView === true ? (
                    ""
                  ) : (
                    <div className="w-50 ml-3 px-4">
                      <label className="label">Patient: </label>
                      <div>
                        <div style={{ marginTop: "-2px" }}>
                          <span>Name:&nbsp;</span>
                          <span style={{ textTransform: `capitalize` }}>
                            {props.patientData.fname}&nbsp;
                            {props.patientData.lname}
                          </span>
                        </div>
                        <div>
                          <span>Phone No:&nbsp;{props.patientData.phno}</span>
                        </div>
                        {props.patientData.typeFlag == "bookvisit" ? (
                          <>
                            {props.patientData.revisit.scheduleddate != null &&
                            props.patientData.revisit.scheduleddate ? (
                              <div>
                                <span>
                                  Requested Time:&nbsp;
                                  {props.patientData.revisit.scheduleddate}{" "}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            {props.patientData.visit &&
                            props.patientData.visit.reqtdate &&
                            props.patientData.visit.reqtdate != null &&
                            props.patientData.visit.reqtdate ? (
                              <div>
                                <span>
                                  Requested Time:&nbsp;
                                  {props.patientData.visit.reqtdate}{" "}
                                  {props.patientData.visit.reqthours}:
                                  {props.patientData.visit.reqtminutes}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="my-2 d-flex justify-content-between align-items-center">
                  <span className="btn" onClick={(event) => prevSevenDays()}>
                    <div className="material-icons arr-prev">
                      arrow_back_ios
                    </div>
                  </span>
                  {weekDays.map((item, idx) => (
                    <div
                      key={idx}
                      className={
                        selecetdDate == item
                          ? "btn btn-date active"
                          : "btn btn-date"
                      }
                      onClick={(event) => handleSelect(event, item)}
                    >
                      {AppointmentScheduleModalFunction.getFormattedDate(item)}
                    </div>
                  ))}
                  <span className="btn" onClick={(event) => nextSevenDays()}>
                    <div className="material-icons arr-next">
                      arrow_forward_ios
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <DialogContent
              id="appointment-body"
              className="py-1 justify-content-between h-100"
            >
              <div className="w-100">
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={toggleMoringTime}
                  className="mb-2"
                  style={{ textAlign: `center` }}
                  size="sm"
                >
                  8:00 AM - 12:00 PM{" "}
                </Button>
                <Collapse className="mt-n2 border py-1" in={collapseMoringTime}>
                  <Table className="w-100 time-table">
                    <TableBody>
                      {!formErrors.counselor ? (
                        <>
                          {console.log("leaveSolts", leaveSolts)}
                          {APPT_MORNING_TIMINGS &&
                            APPT_MORNING_TIMINGS.map((item, idx) => (
                              <TableRow key={idx}>
                                {[
                                  item.type1,
                                  item.type2,
                                  item.type3,
                                  item.type4,
                                ].map((type, colIdx) => (
                                  <TableCell
                                    key={colIdx}
                                    className={`${
                                      timeSlots.indexOf(type) !== -1
                                        ? "slot-booked"
                                        : bookedPatientSlots.includes(type)
                                        ? "slot-alreadybooked"
                                        : ""
                                    } ${
                                      leaveSolts.indexOf(type) !== -1
                                        ? "slot-leave"
                                        : ""
                                    }`}
                                    onClick={(event) =>
                                      addNewAppointment(
                                        item[`time${colIdx + 1}`],
                                        type
                                      )
                                    }
                                  >
                                    {item[`time${colIdx + 1}`]}

                                    <span> (</span>
                                    {bookedCountAtTime &&
                                    bookedCountAtTime[type] > 0
                                      ? bookedCountAtTime[type]
                                      : 0}
                                    <span>)</span>
                                    {selecetdDate &&
                                      leaveSolts.indexOf(type) !== -1 && (
                                        <CloseIcon
                                          sx={{
                                            color: "red",
                                            fontSize: "15px",
                                            padding: "1px",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </Table>
                    {!props.doctorHomeView && (
                      <div style={{ color: "#EC4147" }}>
                        <span className="err-msg">
                          {formErrors.type}
                          {formErrors.apptdate}
                        </span>
                      </div>
                    )}
                </Collapse>

                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={toggleEveTime}
                  className="mb-2 mt-3"
                  style={{ textAlign: `center` }}
                  size="sm"
                >
                  1:00 PM - 8:00 PM{" "}
                </Button>
                <Collapse className="mt-n2 border py-1" in={collapseEveTime}>
                  <Paper>
                    <Table className="w-100 time-table">
                      <TableBody>
                        {!formErrors.counselor ? (
                          <>
                            {APPT_EVENING_TIMINGS &&
                              APPT_EVENING_TIMINGS.map((item, idx) => (
                                <TableRow key={idx}>
                                  {[
                                    item.type1,
                                    item.type2,
                                    item.type3,
                                    item.type4,
                                  ].map((type, i) => (
                                    <TableCell
                                      key={i}
                                      className={
                                        timeSlots.indexOf(type) !== -1
                                          ? "slot-booked"
                                          : bookedPatientSlots.includes(type)
                                          ? "slot-alreadybooked"
                                          : ""
                                      }
                                      onClick={(event) =>
                                        addNewAppointment(
                                          item[`time${i + 1}`],
                                          type
                                        )
                                      }
                                    >
                                      {item[`time${i + 1}`]}
                                      <span> (</span>
                                      {bookedCountAtTime &&
                                      bookedCountAtTime[type] > 0
                                        ? bookedCountAtTime[type]
                                        : 0}
                                      <span>)</span>
                                      {selecetdDate &&
                                        leaveSolts.indexOf(type) !== -1 && (
                                          <CloseIcon
                                            sx={{
                                              color: "red",
                                              fontSize: "15px",
                                              padding: "1px",
                                              marginLeft: "5px",
                                            }}
                                          />
                                        )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                    {!props.doctorHomeView && (
                      <div style={{ color: "#EC4147" }}>
                        <span className="err-msg">
                          {formErrors.type}
                          {formErrors.apptdate}
                        </span>
                      </div>
                    )}
                </Collapse>
              </div>

              {addNewAppmnt && (
                <div className="flex-fill mt-4">
                  <div>
                    <div>
                      <div className="section-heading">
                        {appointmentDetail.title ? (
                          <h5>Edit Appointment</h5>
                        ) : (
                          <h5>Add New Appointment</h5>
                        )}
                      </div>
                      <form
                        className="has-background-white-ter px-4 py-3"
                        onSubmit={onAppointmentFormSubmit}
                      >
                        <div className="field my-4">
                          <label className="label">Title</label>
                          <div className="control">
                            <input
                              name="title"
                              className="input form-control"
                              type="text"
                              placeholder="Enter Title"
                              onChange={(event) =>
                                onInputChange("title", event.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="field my-4">
                          <label className="label">Description</label>
                          <div className="control">
                            <textarea
                              name="description"
                              className="textarea form-control"
                              placeholder="Enter Description"
                              onChange={(event) =>
                                onInputChange("description", event.target.value)
                              }
                            ></textarea>
                          </div>
                          <p className="my-4">
                            <i className="has-text-weight-bold ">
                              Appointment Date:
                            </i>
                            <p>
                              {appointmentDetail.apptdate} &nbsp;{" "}
                              {appointmentDetail.appthours}:
                              {appointmentDetail.apptminutes}&nbsp;
                              {appointmentDetail.appthours > 11 ? "PM" : "AM"}
                            </p>
                          </p>
                        </div>
                        <div className="field is-grouped d-flex my-4">
                          <div className="control mr-3">
                            <button
                              type="submit"
                              className="btn appnt-button is-primary"
                            >
                              Save
                            </button>
                            <div style={{ color: "#EC4147" }}>
                              <span className="err-msg">
                                {formErrors.type}
                                {formErrors.apptdate}
                              </span>
                            </div>
                          </div>
                          <div className="control">
                            <button
                              type="button"
                              className="btn appnt-button btn-outline-dark "
                              onClick={onAppointmentFormCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </DialogContent>
          </div>

          {props.doctorHomeView === true && (
            <>
              {showAppointedPatients && (
                <div className="appointedPatient">
                  <label className="label">Appointed Patients:</label>
                  <div>
                    {appointedPatient.map((item) => (
                      <>
                        <div style={{ marginBottom: "5px" }}>
                          <span>Name:&nbsp;</span>
                          <span style={{ textTransform: `capitalize` }}>
                            {item.fname}&nbsp;{item.lname}
                          </span>
                          <div style={{ fontSize: "12px" }}>
                            Phone No:&nbsp;{item.phno}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {
          <>
            {props.doctorHomeView ? (
              ""
            ) : (
              <DialogActions className="pt-3">
                {flag != "bookvisit" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                    // className="btn btn-submit btn-primary btn-style bottomright"
                    onClick={(event) => cancel(toCancelSlot)}
                  >
                    Cancel Appointment
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  color="primary"
                  // disabled={!appBooked}
                  // className="btn btn-submit btn-primary btn-style bottomright"
                  onClick={(event) => submit(event)}
                >
                  Book Appointment
                </Button>
              </DialogActions>
            )}

            {console.log("selecetdDate:1496", selecetdDate)}
            {flagNotAvailable && (
              <Dialog
                open={flagNotAvailable}
                toggle={toggle}
                className="d-flex w-95 h-90 flex-column-reverse modalDialog1"
              >
                <div>
                  <DialogTitle>
                    Not Available Confirmation..
                    <IconButton
                      aria-label="close"
                      onClick={(event) => toggle(event)}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>

                  <DialogContent className="dealocate">
                    <span>
                      I am not Available on{" "}
                      {format(selecetdDate, "yyyy-MMM-dd")}
                    </span>
                  </DialogContent>

                  <DialogActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <RadioGroup
                      row
                      aria-label="patientCategory"
                      name="patientCategory"
                      value={selectedDay}
                      onChange={(event) => onValueChange(event.target.value)}
                      className="d-flex w-100 justify-content-start"
                    >
                      <FormControlLabel
                        value="FullDay"
                        control={<Radio />}
                        label="Full-Day"
                      />
                      <FormControlLabel
                        value="HalfDay"
                        control={<Radio />}
                        label="Half-Day"
                      />

                      {selectedDay === "HalfDay" && (
                        <>
                          <div className="ml-2" style={{ marginTop: "10px" }}>
                            <select
                              className="select-tag form-control"
                              onChange={(event) =>
                                handleOnChange("fromTime", event.target.value)
                              }
                            >
                              {timesoltList.map((element, idx) => {
                                const types = [
                                  element.time1,
                                  element.time2,
                                  element.time3,
                                  element.time4,
                                ];

                                return types.map((type, index) => (
                                  <option key={`${idx}-${index}`} value={type}>
                                    {element[`time${index + 1}`]}
                                  </option>
                                ));
                              })}
                            </select>
                          </div>

                          <div
                            className="ml-2"
                            style={{ marginTop: "10px", marginLeft: "20px" }}
                          >
                            <select
                              className="select-tag form-control"
                              onChange={(event) =>
                                handleOnChange("toTime", event.target.value)
                              }
                            >
                              {timesoltList.map((element, idx) => {
                                console.log("element: ", element);
                                const types = [
                                  element.time1,
                                  element.time2,
                                  element.time3,
                                  element.time4,
                                ];

                                return types.map((type, index) => {
                                  console.log(type);
                                  return (
                                    <option
                                      key={`${idx}-${index}`}
                                      value={type}
                                    >
                                      {element[`time${index + 1}`]}
                                    </option>
                                  );
                                });
                              })}
                            </select>
                          </div>
                        </>
                      )}
                      <FormControlLabel
                        value="reset"
                        control={<Radio />}
                        label="Reset"
                      />
                    </RadioGroup>
                    {
                      <div style={{ color: "#EC4147",marginRight:"270px" }}>
                        <span className="err-msg">
                          {formErrors.type}
                          {formErrors.apptdate}
                        </span>
                      </div>
                    }
                    <div className="d-flex w-100 justify-content-between p-3 m-0">
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn-style bottomright"
                        onClick={(event) => toggle(event)}
                        style={{ textTransform: "none" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="btn-style bottomleft"
                        onClick={(event) => done(event)}
                        style={{ textTransform: "none" }}
                      >
                        Done
                      </Button>
                    </div>

                  </DialogActions>
                </div>
              </Dialog>
            )}

            {/* <div
              className="d-flex modal-subsection pl-4 position-absolute"
              style={{ bottom: "10px" }}
            >
              <Components.TokenDashBoardDetails updateTokenDashboard={true} />
            </div> */}
          </>
        }
      </Dialog>
    </>
  );
}

export function getFormattedDate(date) {
  const reqdate = new Date(date);
  let formatteddate = format(reqdate, "dd MMM");
  return formatteddate;
}

const AppointmentScheduleModalFunction = {
  getFormattedDate,
};  
export default AppointmentScheduleModal;
